import { useEffect } from 'react';

export const useOutsideClickHandler = (
  ref,
  onOutsideClick,
  whitelist,
  shouldTakeSelectorFirstSlot = false
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      const tagName = event.target.tagName.toLowerCase();
      if (tagName === 'svg' || tagName === 'path') return;
      const selector = shouldTakeSelectorFirstSlot ? (event.target?.id || event.target?.className).split(' ')[0] : event.target?.id || event.target?.className;
      if (
        ((ref?.current && !(ref.current)?.contains(event.target)) || !ref?.current) &&
      !whitelist?.includes(selector)
    ) {
        onOutsideClick();
      }
    }

    window?.addEventListener('mousedown', handleClickOutside);
    return () => {
      window?.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onOutsideClick, ref, whitelist, shouldTakeSelectorFirstSlot]);
};
