import { useCallback, useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Popper, { PopperProps } from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import useFetchDeviceTags from '../../hooks/useFetchDeviceTags';
import useDebounce from '../../hooks/auth/useDebounce';
import Iconify from '../../components/Iconify';
import { useOutsideClickHandler } from '../../hooks/useOutsideClickHandler';
import { colors } from '../../theme/palette/darkPalette';
import { getTagDetails } from '../../utils/helpers';

const StyledTextField = styled(TextField)({
  '& svg': {
    fill: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: colors.green,
    },
  },
});

const PopperComponent = (props: PopperProps) =>
  <Popper
    {...props}
    id="autocomplete-popper"
    placement="bottom"
    sx={{
      '& .MuiAutocomplete-noOptions': {
        display: 'none'
      }
    }}
  />;

const StyledChip = styled(Chip)({
  border: '0.5px solid #fff',
  backgroundColor: '#3f3f3f',
  '&:hover': {
    backgroundColor: '#3f3f3f',
  },
  '& .MuiChip-deleteIcon': {
    fill: '#fff',
    color: '#fff',

    '&:hover': {
      fill: '#fff',
      color: '#fff',
    },
  },
});

const DevicesChartTagFilter = ({ tagOptions = [], onApplyTagFilter }) => {
  const [searchValue, setSearchValue] = useState('');
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(tagOptions);
  
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const { ref: lastElementRef, inView } = useInView();
  const autocompleteRef = useRef(null);
  const isClickedOutside = useRef(false);
  const isInitialTagNameSet = useRef(false);

  const { tags, fetchedData, fetchNextPage, hasNextPage, isFetching } = useFetchDeviceTags({
    debouncedSearchValue,
    enabled: true,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useOutsideClickHandler(autocompleteRef, () => {
    setIsAutocompleteOpen(false);
    isClickedOutside.current = true;
    const tagNames = selectedOptions.map(tag => tag.name);
    const tagIds = selectedOptions.map(tag => tag.id);
    onApplyTagFilter(tagNames, tagIds);
  }, ['autocomplete-option-text', 'autocomplete-option', 'delete-icon', 'recent-day-option']);

  const onSearchChange = useCallback(event => {
    if (!!selectedOptions?.length) return;

    setSearchValue(event?.target?.value.trim());
  }, [selectedOptions?.length]);

  const renderInput = useCallback(params => {
    return (
      <StyledTextField
        {...params}
        variant="standard"
        InputLabelProps={{
          sx: {
            color: '#fff',
          },
        }}
        InputProps={{
          ...params.InputProps,
          startAdornment: (
            <Iconify icon="mingcute:filter-line" {...params.InputProps.startAdornment} sx={{ ...params?.InputProps.startAdornment?.sx, mr: 1 }} />
          ),
          sx: {
            ...params.InputProps.sx,
            width: '100%',
            maxWidth: '150px',
            '& input': {
              caretColor: selectedOptions?.length ? 'transparent': '#fff',
            },
          }
        }}
        onChange={onSearchChange}
        placeholder="Select tag"
      />
    )
  }, [selectedOptions, onSearchChange]);

  const onResetTag = useCallback((tagId) => {
    setSearchValue('');
    setSelectedOptions(prevState => {
      const filteredTags = prevState.filter(tag => tag.id !== tagId);
      const tagIds = filteredTags.map(tag => tag.id);
      onApplyTagFilter(filteredTags.map(tag => tag.name), tagIds);
      return filteredTags;
    });
    isInitialTagNameSet.current = false;
  }, [onApplyTagFilter]);

  const isTagChange = useCallback((event, newTag) => {
    const tagChange = {
      addedTag: newTag.find(tag => !selectedOptions.some(selected => selected.id === tag.id)),
      removedTag: selectedOptions.find(tag => !newTag.some(newT => newT.id === tag.id)),
    };

    if (tagChange.addedTag) {
      const [tagNames, tagIds] = getTagDetails(selectedOptions, tagChange.addedTag);
      onApplyTagFilter(tagNames, tagIds);
      setSelectedOptions(prevState => [...prevState, tagChange.addedTag]);
    } else if (tagChange.removedTag) {
      onResetTag(tagChange.removedTag.id);
    }
  }, [selectedOptions, onApplyTagFilter, onResetTag]);

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && event?.target?.value) {
      const selectedTag = tags.find(tag => tag.name === event.target.value);
      if (!selectedTag) return;

      const tagNames = selectedOptions.map(tag => tag.name);
      const tagIds = selectedOptions.map(tag => tag.id);
      setSelectedOptions([...selectedOptions, selectedTag]);
      onApplyTagFilter([...tagNames, selectedTag.name], [...tagIds, selectedTag.id]);
      setIsAutocompleteOpen(false);
    }
  }, [tags, selectedOptions, onApplyTagFilter]);

  return (
    <Box>
      <Autocomplete
        size="small"
        ref={autocompleteRef}
        multiple
        PopperComponent={PopperComponent}
        disableClearable
        value={selectedOptions}
        loading={isFetching}
        open={isAutocompleteOpen}
        onOpen={() => setIsAutocompleteOpen(true)}
        onChange={isTagChange}
        onKeyDown={onKeyDown}
        isOptionEqualToValue={(option, val) => option?.name === val?.name}
        filterOptions={createFilterOptions({ trim: true })}
        options={fetchedData?.data || []}
        renderInput={renderInput}
        renderOption={({ key, ...optionProps }, option) => (
          <Stack
            {...optionProps}
            direction="row"
            justifyContent="space-between"
            key={key}
            id="autocomplete-option"
            ref={tags[tags.length - 1]?.name === option.name ? lastElementRef : null}>
            <Box id="autocomplete-option-text" sx={{ flex: 1 }}>{option.name}</Box>
          </Stack>
        )}
        getOptionLabel={option => option?.name}
        renderTags={(value) =>
          value.map((option: string) => {
            if (!option?.name) return null;

            return (
              <Typography>
                {`${option.name}, `}
              </Typography>
            );
          })
        }
        sx={{
          width: '150px',
        }}
      />
      <Box id="chips-wrapper" sx={{
        mt: 1,
        display: 'flex',
        gap: 1,
        height: 'auto',
        flexWrap: 'wrap' }}>
        {selectedOptions.map(option => (
          <StyledChip
            key={option.id}
            label={option.name}
            onDelete={() => onResetTag(option.id)}
            deleteIcon={<Iconify icon="mdi:close" />}
          />
        ))}
      </Box>
    </Box>
  )
};

export default DevicesChartTagFilter;
