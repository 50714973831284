import React, { memo } from 'react';
import { styled } from '@mui/material/styles';
import MuiListItemText, { listItemTextClasses } from '@mui/material/ListItemText';

const StyledListItemText = styled(MuiListItemText, {
  shouldForwardProp: prop => prop !== 'primaryStyles' && prop !== 'secondaryStyles',
})(({ theme, primaryStyles, secondaryStyles }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  [`& .${listItemTextClasses.primary}`]: {
    ...primaryStyles,
    fontSize: theme.typography.pxToRem(primaryStyles.fontSize),
    lineHeight: primaryStyles.lineHeight,
    fontWeight: primaryStyles.fontWeight,
    fontFamily: 'Inter',
    color: primaryStyles.color,
  },
  ...(secondaryStyles
    ? {
        [`& .${listItemTextClasses.secondary}`]: {
          ...secondaryStyles,
          fontSize: theme.typography.pxToRem(secondaryStyles.fontSize),
          lineHeight: secondaryStyles.lineHeight,
          fontWeight: secondaryStyles.fontWeight,
          fontFamily: 'Inter',
          color: secondaryStyles.color,
        },
      }
    : {}),
}));

const ListItemText = props => <StyledListItemText {...props} />;

export default memo(ListItemText);
