import MuiAlert from '@mui/material/Alert';

const Alert = ({ severity, styles = {}, ...props }) => (
  <MuiAlert
    {...props}
    severity={severity}
    sx={{
      ...(severity === 'error'
        ? {
            backgroundColor: '#DE312B',
            color: '#fff',
          }
        : {}),
      ...styles,
    }}
  />
);

export default Alert;
