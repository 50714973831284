import AlertTitle from '@mui/material/AlertTitle';
import Alert from '../../../components/Alert';

const ErrorAlert = ({ title, description }) => (
  <Alert variant="filled" severity="error" styles={{ maxWidth: '504px' }}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {description}
  </Alert>
);

export default ErrorAlert;
