import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
//
import lightPalette from './palette/lightPalette';
import typography from './typography';
import componentsOverride from './overrides';
import ColorContext from '../context/ColorContext';
import darkPalette from './palette/darkPalette';

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

const palette = {
  light: lightPalette,
  dark: darkPalette,
};

export default function ThemeProvider({ children }) {
  const [mode, setMode] = useState('');

  // revert this code when client will like light mode, because currenlty client need only dark mode
  // const selectedMode = mode || prefersDarkMode ? 'dark' : 'light';
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const selectedMode = 'dark';

  const toggleMode = useCallback(
    mode => {
      setMode(mode);
    },
    [setMode],
  );

  const themeOptions = useMemo(
    () => ({
      palette: {
        mode: selectedMode,
        ...palette[selectedMode],
      },
      shape: { borderRadius: 8 },
      typography,
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-display: swap;
              font-family: 'Inter';
              font-style: normal;
              font-weight: 300;
              src: url('../fonts/inter-v12-latin-300.woff2') format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              src: url('../fonts/inter-v12-latin-regular.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 500;
              src: url('../fonts/inter-v12-latin-500.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              src: url('../fonts/inter-v12-latin-600.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              src: url('../fonts/inter-v12-latin-700.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 800;
              src: url('../fonts/inter-v12-latin-800.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-display: swap; 
              font-family: 'Inter';
              font-style: normal;
              font-weight: 900;
              src: url('../fonts/inter-v12-latin-900.woff2') format('woff2'); 
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
        },
        MuiButton: {
          defaultProps: {
            disableRipple: true,
          },
          variants: [
            {
              props: { size: 'medium' },
              style: {
                height: '40px',
              },
            },
            {
              props: { size: 'large' },
              style: {
                height: '44px',
              },
            },
          ],
        },
      },
    }),
    [selectedMode],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <ColorContext.Provider value={{ mode, toggleMode }}>{children}</ColorContext.Provider>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
