import { memo } from 'react';
import Button from './Button';

const BackButton = ({ onClick, children }) => (
  <Button
    variant="text"
    onClick={onClick}
    hasHover={false}
    hasActive={false}
    styles={{
      color: '#B5B5B5',
    }}>
    {children}
  </Button>
);

export default memo(BackButton);
