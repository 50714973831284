import { pagesText } from '../../constants/pagesText';
import EnvironmentGuard from '../../components/EnvironmentGuard';
import Page from '../../components/Page';
import DevicesCharts from './DevicesCharts';

const DevicesDashboard = () => (
  <Page title={pagesText.dashboard.title}>
    <EnvironmentGuard>
      <DevicesCharts />
    </EnvironmentGuard>
  </Page>
);

export default DevicesDashboard;
