import TableRow from '@mui/material/TableRow';
import LoadingRow from './LoadingRow';

const LoadingRows = ({ cellColSpan, columnCount, rowCount, cellStyles, ...props }) => {
  return Array.from({ length: rowCount }).map((row, index) => (
    <TableRow key={index} {...props}>
      <LoadingRow colSpan={cellColSpan} columnCount={columnCount} cellStyles={cellStyles} />
    </TableRow>
  ));
};

export default LoadingRows;
