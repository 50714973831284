import { useEffect, useState } from 'react';

export const useDebounce = (text, delay, callBack) => {
  const [debounceText, setDebounceText] = useState(text);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceText(text);
      if (typeof callBack === 'function') {
        callBack();
      }
    }, delay);

    return () => clearTimeout(handler);
  }, [callBack, delay, text]);

  return debounceText;
};

export default useDebounce;
