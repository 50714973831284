import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useDevice from '../../../hooks/deviceDetails/useDevice';
import { Loader } from '../../../components';
import { formatDate } from '../../../utils/formatTime';

const fieldsToRender = [
  { key: 'name', label: 'Name (Computer Name)' },
  { key: 'uniqueExternalId', label: 'Unique_External_id' },
  { key: 'status', label: 'Status', format: value => value || 'Unknown' }, // Default to 'Unknown'
  { key: 'tag', label: 'Tag' },
];

const osDetails = [
  { key: 'osVersion', label: 'OSVersion' },
  { key: 'osSku', label: 'SKU' },
  { key: 'osType', label: 'OSType' },
];

const additionalFields = [
  { key: 'logCollectorVersion', label: 'LogCollector version' },
  { key: 'createdAt', label: 'Created Date', format: formatDate },
  { key: 'updatedAt', label: 'Updated Date', format: formatDate },
  { key: 'lastContactDate', label: 'Last Contact Date', format: formatDate },
  {
    key: 'lastWdacHeartbeatDate',
    label: 'WDAC Last Heartbeat Date',
    format: value => (value ? formatDate(value) : 'No heartbeat was ever received'),
  },
  {
    key: 'wdacStatus',
    label: 'WDAC Status',
    format: value => (value ? value : 'None'),
  },
];

const Container = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      width: '100%',
    })}>
    {children}
  </Box>
);

const DeviceGeneralSection = () => {
  const { deviceId } = useParams();
  const { isLoading, data: device } = useDevice({ deviceId });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Table>
          <TableBody>
            {fieldsToRender.map(({ key, label, format }) => (
              <TableRow key={key}>
                <TableCell>{label}:</TableCell>
                <TableCell>
                  {key === 'tag'
                    ? device?.tag?.name || 'no data'
                    : format
                      ? format(device?.device[key])
                      : device?.device[key] || 'no data'}
                </TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell colSpan={2}>OS Details</TableCell>
            </TableRow>
            {osDetails.map(({ key, label }) => (
              <TableRow key={key}>
                <TableCell sx={{ pl: 5 }}> - {label}:</TableCell>
                <TableCell>{device?.device[key] || 'no data'}</TableCell>
              </TableRow>
            ))}

            {additionalFields.map(({ key, label, format }) => (
              <TableRow key={key}>
                <TableCell>{label}:</TableCell>
                <TableCell>
                  {format ? format(device?.device[key]) : device?.device[key] || 'no data'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </Container>
  );
};

export default DeviceGeneralSection;
