import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,
  lineHeight: '24px',
  color: '#fff',
}));

const GeneralDetailLayout = ({ title, TitleComponent, layoutDirection = 'row', children }) => (
  <Stack direction={layoutDirection} justifyContent="space-between" gap={3}>
    <Stack direction="row" justifyContent="space-between">
      <StyledTypography>{title}</StyledTypography>
      {TitleComponent && <TitleComponent />}
    </Stack>
    {children}
  </Stack>
);

export default GeneralDetailLayout;
