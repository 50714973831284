export default class EnvironmentIdService {
  static getId() {
    return localStorage.getItem('defaultEnvironmentId');
  }
  static setId(refreshToken) {
    return localStorage.setItem('defaultEnvironmentId', refreshToken);
  }
  static removeId() {
    return localStorage.removeItem('defaultEnvironmentId');
  }
}
