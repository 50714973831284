export const USER_QUERY_KEY = 'user';
export const ENVIRONEMNTS_QUERY_KEY = 'environments';
export const APPLICATIONS_QUERY_KEY = 'applications';
export const APPLICATION_VERSIONS_QUERY_KEY = 'applicationVersions';
export const DOWNLOADS_QUERY_KEY = 'downloads';
export const CUSTOMER_QUERY_KEY = 'customer';
export const LOGIN_QUERY = 'login';
export const REGISTER_QUERY = 'register';
export const RESEND_VERIFICATION_QUERY = 'resendVerification';
export const SEND_PASSWORD_RESET_QUERY = 'sendPasswordReset';
export const REFRESH_QUERY = 'refresh';
export const PASSWORD_RESET_WITH_TOKEN_QUERY = 'passwordResetWithToken';
export const CUSTOMER_SETUP_ACCOUNT_QUERY = 'customerSetupAccount';
export const COMPANY_USERS_QUERY_KEY = 'companyUsers';
export const INVITE_CUSTOMER_QUERY_KEY = 'invite';
export const CUSTOMERS_QUERY_KEY = 'customers';
export const VALIDATE_CUSTOMER_INVITE_QUERY = 'validateCustomerInvite';
export const DEVICES_QUERY_KEY = 'devices';
export const DEVICE_TAGS_QUERY_KEY = 'deviceTags';
export const APPLICATION_QUERY_KEY = 'application';
export const DEVICE_QUERY_KEY = 'device';
export const PUBLISHERS_QUERY_KEY = 'publishers';
export const UNASIGNED_FILES_QUERY_KEY = 'unasignedFiles';
export const OUTSIDE_PATH_RULES_QUERY_KEY = 'outsidePathRules';
export const WRITABLE_FOLDERS_QUERY_KEY = 'writableFolders';
export const ENVIRONMENT_NAME_QUERY_KEY = 'environmentName';
export const DEVICES_DASHBOARD_QUERY_KEY = 'devicesDashboard';
export const TAG_DEVICES_COUNT_QUERY_KEY = 'tagDevicesCount';
export const TAG_DEVICES_QUERY_KEY = 'tagDevices';
export const OS_TYPES_QUERY_KEY = 'osTypes';
export const OS_SKUS_QUERY_KEY = 'osSkus';
export const OS_VERSIONS_QUERY_KEY = 'osVersions';
export const LAST_DOWNLOAD_DATES_KEY = 'lastDownloadDates';
