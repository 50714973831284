import { memo } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import BackButton from '../BackButton';
import { pagesText } from '../../constants/pagesText';
import Button from '../Button';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  marginTop: theme.spacing(2.5),
  marginRight: theme.spacing(2.75),
}));

const SidebarFooter = ({ onSuccess, onClose, isApplyFiltersDisabled, isActiveFilterSection }) => (
  <StyledBox>
    <Stack gap={1} direction="row">
      {isActiveFilterSection && <BackButton onClick={onClose}>{pagesText.cancelButton}</BackButton>}
      <Button variant="outlined" onClick={onSuccess} disabled={isApplyFiltersDisabled}>
        {isActiveFilterSection
          ? pagesText.filters.applyFiltersButton
          : pagesText.filters.saveFiltersButton}
      </Button>
    </Stack>
  </StyledBox>
);

export default memo(SidebarFooter);
