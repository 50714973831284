import { memo, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { useInView } from 'react-intersection-observer';
import TableExpandedRow from './TableExpandedRow';
import { APPLICATION_VERSIONS_QUERY_KEY } from '../../constants/query';
import NoDataCell from '../NoDataCell';
import { pagesText } from '../../constants/pagesText';
import LoadingRows from './LoadingRows';
import { EXPANDED_ROWS_LIMIT } from '../../constants/table';
import applicationsService from '../../services/applications.service';

const appsExpandedRowColumnsOrder = [
  'environmentApplicationName',
  'softwareCenterName',
  'publisher',
  '',
  'latestVersion',
  'installSize',
  'createdAt',
];

const ExpandedRow = memo(({ onClick, source, applicationId, activePage, columnsOrder }) => {
  const [showAllVersions] = useState(false);
  const expandedRowColumnsOrder = useMemo(() => {
    if (source === 'apps') {
      return appsExpandedRowColumnsOrder;
    }

    return columnsOrder;
  }, [source, columnsOrder]);

  const { ref: lastElementRef, inView } = useInView();

  const queryClient = useQueryClient();
  const { data, error, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    useInfiniteQuery(
      [APPLICATION_VERSIONS_QUERY_KEY, applicationId],
      async ({ pageParam = 1 }) =>
        await applicationsService.getApplicationVersions({
          applicationId,
          // TODO: currently page query not working
          page: 1,
          limit: (pageParam - 1) * EXPANDED_ROWS_LIMIT + EXPANDED_ROWS_LIMIT,
        }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const flatAllPages = allPages?.flatMap(page => page.versions);
          if (!showAllVersions) return undefined;

          return flatAllPages.length <= lastPage?.count
            ? flatAllPages.length / EXPANDED_ROWS_LIMIT + 1
            : undefined;
        },
      },
    );

  useEffect(() => {
    return () => {
      queryClient.removeQueries([APPLICATION_VERSIONS_QUERY_KEY, applicationId]);
    };
  }, [queryClient, applicationId]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const versionsData = useMemo(
    () => ({
      versions: data?.pages[data.pages.length - 1]?.versions || [],
      count: data?.pages[0].count || 0,
    }),
    [data],
  );

  if (isLoading) {
    return (
      <LoadingRows
        columnCount={expandedRowColumnsOrder.length + 1}
        rowCount={EXPANDED_ROWS_LIMIT}
      />
    );
  }

  return (
    <>
      {error || !versionsData.versions?.length ? (
        <NoDataCell>{pagesText.noVersionFound}</NoDataCell>
      ) : (
        <>
          <TableExpandedRow
            onClick={onClick}
            versions={versionsData.versions}
            ref={lastElementRef}
            columnsOrder={expandedRowColumnsOrder}
          />
          {isFetchingNextPage && (
            <LoadingRows
              columnCount={expandedRowColumnsOrder.length + 1}
              rowCount={EXPANDED_ROWS_LIMIT}
            />
          )}
        </>
      )}
    </>
  );
});

export default ExpandedRow;
