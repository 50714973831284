import { useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ConditionComponent from './ConditionComponent';
import Tabs from './Tabs';
import Iconify from './Iconify';
import IconButton from './IconButton';

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 700,
  lineHeight: '44px',
  color: theme.palette.text.primary,
}));

const Container = styled(Box)({
  height: '100%',
});

const Wrapper = styled(Box)({
  width: '100%',
  maxWidth: '952px',
  margin: 'auto',
});

const TabsPageLayout = ({
  title,
  tabs,
  wrapperProps = {},
  hasBackButton = false,
  backNavigationPath,
  titleProps = {},
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateBack = useCallback(() => {
    navigate(backNavigationPath || -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backNavigationPath]);
  const onChangeTab = useCallback(
    (event, newValue) => {
      navigate(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Container>
      <Wrapper {...wrapperProps}>
        <Stack direction="row" gap={1}>
          <ConditionComponent
            condition={hasBackButton}
            renderElement={
              <IconButton onClick={navigateBack}>
                <Iconify
                  icon="iconamoon:arrow-left-2-duotone"
                  color="#ddd"
                  width={24}
                  height={24}
                />
              </IconButton>
            }
          />
          <ConditionComponent
            condition={title}
            renderElement={<StyledTitle sx={titleProps}>{title}</StyledTitle>}
          />
        </Stack>
        <Tabs value={pathname} onChange={onChangeTab} tabs={tabs} />
        <Outlet />
      </Wrapper>
    </Container>
  );
};

export default TabsPageLayout;
