import { defaultDeviceFilters } from '../components/Filters/constants';

export const getDeviceWdacStatusFilerQuery = ({ status, startDate, endDate, tagIds = [] }) => ({
  ...defaultDeviceFilters,
  // wdacDeviceStatus: [
  //   [
  //     {
  //       ...defaultDeviceFilters.wdacDeviceStatus[0][0],
  //       value: status,
  //     },
  //   ],
  // ],
  ...(!!tagIds.length
    ? { TagName: [[{ ...defaultDeviceFilters.TagName[0][0], value: tagIds || [] }]] }
    : {}),
  ...(startDate && endDate
    ? {
        lastWdacHeartbeatDateFilter: [
          [
            {
              ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[0][0],
              value: startDate,
            },
          ],
          [
            {
              ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[1][0],
              value: endDate,
            },
          ],
        ],
      }
    : {}),
});

export const getDeviceHealthStatusFilterQuery = ({ status, startDate, endDate, tagIds = [] }) => ({
  ...defaultDeviceFilters,
  // healthStatus: [
  //   [
  //     {
  //       ...defaultDeviceFilters.healthStatus[0][0],
  //       value: status,
  //     },
  //   ],
  // ],
  ...(tagIds.length
    ? { TagName: [[{ ...defaultDeviceFilters.TagName[0][0], value: tagIds || [] }]] }
    : {}),
  ...(startDate && endDate
    ? {
        lastWdacHeartbeatDateFilter: [
          [
            {
              ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[0][0],
              value: startDate,
            },
          ],
          [
            {
              ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[1][0],
              value: endDate,
            },
          ],
        ],
      }
    : {}),
});

export const getDeviceTagIdFilterQuery = tagId => ({
  ...defaultDeviceFilters,
  tagId: [
    [
      {
        ...defaultDeviceFilters.tagId[0][0],
        value: tagId,
      },
    ],
  ],
});

export const getDeviceLastWdacHeartbeatFilterQuery = ({ startDate, endDate }) => ({
  ...defaultDeviceFilters,
  lastWdacHeartbeatDateFilter: [
    [
      {
        ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[0][0],
        value: startDate,
      },
    ],
    [
      {
        ...defaultDeviceFilters.lastWdacHeartbeatDateFilter[1][0],
        value: endDate,
      },
    ],
  ],
});
