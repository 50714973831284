import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { CUSTOMER_QUERY_KEY, ENVIRONEMNTS_QUERY_KEY } from '../../constants/query';
import environmentService from '../../services/environment.service';
import useSearchParams from '../useSearchParams';
import useQueryData from '../useQueryData';
import { customerIdSelector } from '../../selectors/customer';

const useUpdateEnvironments = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { page = 1 } = useSearchParams();
  const parsedPage = Number(page);
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  return useMutation({
    mutationFn: values =>
      environmentService.editEnvironment({
        customerEnvId: values.id,
        configManagerSupportId: values.configManagerSupportId,
        description: values.description,
        location: values.location,
        name: values.name,
      }),
    onMutate: async values => {
      await queryClient.cancelQueries({
        queryKey: [ENVIRONEMNTS_QUERY_KEY, customerId],
      });

      const previousEnvironments = queryClient.getQueryData([ENVIRONEMNTS_QUERY_KEY, customerId]);

      queryClient.setQueryData([ENVIRONEMNTS_QUERY_KEY, customerId], prevData => ({
        ...prevData,
        pages: prevData?.pages.map((page, index) => {
          if (index === parsedPage - 1) {
            return {
              ...page,
              environments: page.environments.map(env =>
                env.id === values.id ? { ...env, ...values } : env,
              ),
            };
          }
          return page;
        }),
      }));

      return { previousEnvironments: previousEnvironments?.pages[0] };
    },
    // onError: (err, newTodo, context) => {
    //   queryClient.setQueryData([ENVIRONEMNTS_QUERY_KEY, customerId], context.previousEnvironments);

    //   enqueueSnackbar(err, {
    //     variant: 'error',
    //     autoHideDuration: 3000,
    //   });
    // },
    onSuccess: () => {
      queryClient.invalidateQueries([ENVIRONEMNTS_QUERY_KEY, customerId]);
      enqueueSnackbar('Environment updated successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useUpdateEnvironments;
