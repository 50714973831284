export const handleUrlDownload = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) {
    link.setAttribute('download', fileName);
  }
  link.click();
  link.remove();
};

export const handleZipFileDownload = (response, fileName) => {
  const blob = new Blob([response], { type: 'application/zip' });
  const url = window.URL.createObjectURL(blob);
  handleUrlDownload(url, fileName);
  window.URL.revokeObjectURL(url);
};
