import { useQuery } from 'react-query';
import {  DEVICE_QUERY_KEY } from '../../constants/query';
import DeviceService from '../../services/device.service';
import { useEntityManager } from '../../context/EntityManagerContext';

const useDevice = ({ deviceId }) => {
  const { customerEnvId } = useEntityManager();

  return useQuery([DEVICE_QUERY_KEY, deviceId], () =>
    DeviceService.getDevice({ deviceId: deviceId, customerEnvId: customerEnvId }),
  );
};

export default useDevice;
