import { useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import GeneralTextField from './GeneralTextField';
import DetailLayout from './DetailLayout';
import SettingsActions from './SettingsActions';
import useQueryData from '../../../hooks/useQueryData';
import { CUSTOMER_QUERY_KEY, USER_QUERY_KEY } from '../../../constants/query';
import {
  userFirstNameSelector,
  userLastNameSelector,
  userEmailSelector,
} from '../../../selectors/user';
import Iconify from '../../../components/Iconify';
import CompanySectionContainer from '../../../components/CompanySectionContainer';
import GeneralDetailLayout from '../../../components/GeneralDetailLayout';
import { pagesText } from '../../../constants/pagesText';
import useMutateUser from '../../../hooks/user/useMutateUser';
import { companyNameSelector } from '../../../selectors/customer';

const StyledAvatar = styled(Avatar)({
  width: 56,
  height: 56,
  backgroundColor: '#6B7280',
  color: '#fff',
  padding: '8px',
});

const BasicDetails = () => {
  const firstName = useQueryData(USER_QUERY_KEY, userFirstNameSelector);
  const lastName = useQueryData(USER_QUERY_KEY, userLastNameSelector);
  const email = useQueryData(USER_QUERY_KEY, userEmailSelector);
  const companyName = useQueryData(CUSTOMER_QUERY_KEY, companyNameSelector);

  const mutateUser = useMutateUser();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      firstName,
      lastName,
      companyName,
      email,
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      ...(companyName
        ? {
            companyName: Yup.string().required('Company name is required'),
          }
        : {}),
      email: Yup.string().required('Email is required'),
    }),
    onSubmit: async values => {
      mutateUser.mutate(values, {
        onSuccess: () => {
          enqueueSnackbar(pagesText.userProfileEditMessage, {
            variant: 'success',
            autoHideDuration: 3000,
          });
        },
        onError: error => {
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        },
      });
    },
  });

  const onCancelEmail = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  return (
    <CompanySectionContainer>
      <GeneralDetailLayout title="Basic Details">
        <DetailLayout>
          <Stack direction="row" gap={2}>
            <StyledAvatar src="">
              <Iconify icon="mdi:user-circle" color="#fff" width={32} height={32} />
            </StyledAvatar>
          </Stack>
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={3}>
              <GeneralTextField
                label={pagesText.authLabels.firstName}
                error={formik.touched.firstName && !!formik.errors.firstName}
                helperText={formik.touched.firstName && formik.errors.firstName}
                {...formik.getFieldProps('firstName')}
              />
              <GeneralTextField
                label={pagesText.authLabels.lastName}
                error={formik.touched.lastName && !!formik.errors.lastName}
                helperText={formik.touched.lastName && formik.errors.lastName}
                {...formik.getFieldProps('lastName')}
              />
              <GeneralTextField
                disabled={!companyName}
                label={pagesText.authLabels.companyName}
                error={formik.touched.companyName && !!formik.errors.companyName}
                helperText={formik.touched.companyName && formik.errors.companyName}
                {...formik.getFieldProps('companyName')}
              />
              <GeneralTextField
                label={pagesText.authLabels.email}
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
              />
              <SettingsActions onCancel={onCancelEmail} isSubmitDisabled={!formik.dirty} />
            </Stack>
          </form>
        </DetailLayout>
      </GeneralDetailLayout>
    </CompanySectionContainer>
  );
};

export default BasicDetails;
