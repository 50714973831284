import { useEffect, useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useInView } from 'react-intersection-observer';
import useQueryParams from './useQueryParams';

const useInfiniteFetch = ({
  queryKey,
  fetchFunction,
  expandedRowsLimit,
  itemName,
  enabled = true,
  searchValue: inputSearchValue,
}) => {
  const { ref: lastElementRef, inView } = useInView();
  const {
    queryObject: { filters, ...rest },
    onApplySearchParams,
  } = useQueryParams();

  const { hasNextPage, data, fetchNextPage, isLoading, ...props } = useInfiniteQuery(
    inputSearchValue ? [queryKey, inputSearchValue] : queryKey,
    async ({ pageParam = 1 }) => {
      onApplySearchParams({
        ...(filters ? { filters } : {}),
        ...rest,
        page: pageParam,
        limit: expandedRowsLimit,
      });

      return await fetchFunction({
        page: pageParam,
        limit: expandedRowsLimit,
      });
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length;
        const isMoreData = lastPage[itemName].length >= expandedRowsLimit;

        if (isMoreData) {
          return currentPage + 1;
        }

        return undefined;
      },
      enabled,
    },
  );

  const fetchedData = useMemo(() => {
    if (
      data?.pages?.length > 1 &&
      data?.pages[0][itemName].length < data?.pages[1][itemName]?.length
    ) {
      const lastPage = data?.pages[data.pages.length - 1];

      return {
        ...lastPage,
        data: lastPage[itemName],
        count: lastPage.count,
        ...(lastPage.totalCount ? { totalCount: lastPage.totalCount } : {}),
      };
    }

    const duplicateFilteredList = data?.pages?.reduce(
      (acc, page) => {
        acc.data = [...acc.data, ...page[itemName]];
        acc.count = Math.max(acc.count, page.count);
        if (page.totalCount) acc.totalCount = page.totalCount;
        return acc;
      },
      {
        data: [],
        count: 0,
      },
    );

    const lastPage = data?.pages[data.pages?.length - 1];
    if (!lastPage)
      return {
        data: [],
        count: 0,
      };
    if (Object.keys(lastPage).length < 2) {
      return {
        ...lastPage,
        ...lastPage[itemName],
      };
    }

    return {
      ...lastPage,
      ...duplicateFilteredList,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.pages, itemName]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  return {
    ...fetchedData,
    lastElementRef,
    hasNextPage,
    isLoading,
    ...props,
  };
};

export default useInfiniteFetch;
