import Stack from '@mui/material/Stack';
import BasicDetails from './BasicDetails';
import ChangePassword from './ChangePassword';

const General = () => (
  <Stack gap={4}>
    <BasicDetails />
    <ChangePassword />
  </Stack>
);

export default General;
