import { useMutation, useQueryClient } from 'react-query';
import UserService from '../../services/user.service';
import { USER_QUERY_KEY } from '../../constants/query';

const useMutateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(UserService.editUser, {
    onMutate: values => {
      queryClient.setQueryData(USER_QUERY_KEY, prevProps => ({
        ...prevProps,
        ...values,
      }));
    },
  });
};

export default useMutateUser;
