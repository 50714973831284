import Box from '@mui/material/Box';

const CompanySectionContainer = ({ children }) => (
  <Box
    sx={theme => ({
      backgroundColor: '#484848',
      boxShadow: '0px 1px 2px 0px #64748B1A',
      borderRadius: theme.spacing(1),
      padding: '32px 24px',
      maxWidth: '952px',
      width: '100%',
    })}>
    {children}
  </Box>
);

export default CompanySectionContainer;
