import api from './api';

const BASE_PATH = '/application';

const getApplication = ({ id }) => api.get(`${BASE_PATH}/${id}`);
const getPublishers = ({ id, search, limit, page }) =>
  api.get(`${BASE_PATH}/${id}/codeSigners`, {
    params: {
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const outsidePathRules = ({ id, search, limit, page }) =>
  api.get(`${BASE_PATH}/${id}/outsidePathRuleFiles`, {
    params: {
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const userWritableFolders = ({ id, search, limit, page }) =>
  api.get(`${BASE_PATH}/${id}/userWritableFolders`, {
    params: {
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const unsignedFiles = ({ id, search, limit, page }) =>
  api.get(`${BASE_PATH}/${id}/unsignedFiles`, {
    params: {
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const ApplicationService = {
  getApplication,
  getPublishers,
  outsidePathRules,
  userWritableFolders,
  unsignedFiles,
};

export default ApplicationService;
