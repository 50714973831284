import * as Yup from 'yup';
import { IdField } from './constants';

export const nameValidationField = Yup.string().trim().required('Environment name is required');

const configManagerValidationField = Yup.string()
  .trim()
  .test('len', 'Support id must be 44 characters', val => val?.length === 44)
  .required('Environment supportId is required');

export const guidRegex =
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const azureTenantIdValidationField = Yup.string()
  .trim()
  .test('len', 'Azure Tenant ID must be 36 characters', val => val?.length === 36)
  .required('Azure Tenant ID is required')
  .matches(guidRegex, 'Azure Tenant ID must be a valid GUID');

const generatedValidationField = Yup.string()
  .trim()
  .test('len', 'Custom id must be 36 characters', val => val?.length === 36)
  .required('Environment id is required')
  .matches(guidRegex, 'Custom ID must be a valid GUID');

export const idValidationFields = {
  [IdField.CONFIG_MANAGER]: configManagerValidationField,
  [IdField.INTUNE]: azureTenantIdValidationField,
  [IdField.OTHER]: generatedValidationField,
};
