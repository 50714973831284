import Container from '@mui/material/Container';
import Page from '../components/Page';
import { Loader } from 'src/components';
import LogoLoader from 'src/components/LogoLoader';
import Iconify from 'src/components/Iconify';
import { Stack } from '@mui/material';

const FAQ = () => (
  <Page title="Frequently asked question">
    <Container>
      <Stack direction="column" spacing={1}>
        <Loader />
        <Loader
          icon={<Iconify icon="material-symbols:lock" color="#87c8e0" width={24} height={24} />}
        />
        <LogoLoader />
        <LogoLoader
          icon={<Iconify icon="material-symbols:lock" color="#87c8e0" width={24} height={24} />}
        />
      </Stack>
    </Container>
  </Page>
);

export default FAQ;
