import { forwardRef, memo, useCallback, useState } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import IconButton from '../IconButton';
import Iconify from '../Iconify';
import ExpandedRow from './ExpandedRow';
import TableNameCell from './TableNameCell';
import GenericTableCell from '../GenericTableCell';
import ConditionComponent from '../ConditionComponent';
import GenericCell from './GenericCell';
import { isDateString } from '../../utils/date.util';
import { formatDate } from '../../utils/formatTime';
import { PORTAL_ROUTE } from '../../constants/routes';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef RowProps
 * @property {Object} row
 */

/**
 * Row component.
 * @param {RowProps} props - props of component
 */

const Row = forwardRef(
  (
    {
      row,
      columnData,
      ActionComponent,
      hasActions = true,
      markedRows = [],
      changeMarkedItem,
      hasSelection = false,
      isClickable = false,
      renderCustomCell,
      rowClickHandler,
      source,
    },
    ref,
  ) => {
    const isRowActive = markedRows[row?.email];
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const onToggleOpen = useCallback(
      event => {
        event?.stopPropagation();
        setOpen(prevOpen => !prevOpen);
      },
      [setOpen],
    );

    const navigateToDeviceDetails = useCallback(
      id => {
        navigate(`${PORTAL_ROUTE}/devices/${id}/general`);
      },
      [navigate],
    );

    const onChangeMarkedItem = useCallback(() => {
      const newMarkedRows = { ...markedRows };
      if (newMarkedRows[row?.email]) {
        delete newMarkedRows[row.email];
      } else {
        newMarkedRows[row.email] = row;
      }

      changeMarkedItem(newMarkedRows);
    }, [row, changeMarkedItem, markedRows]);

    const renderCell = useCallback(
      (column, params) => {
        switch (column?.name || column) {
          case 'firstName':
            return (
              <GenericCell
                cellValue={row.firstName}
                isRowActive={params.isRowActive}
                {...column?.props}
              />
            );
          case 'environmentApplicationName':
            return (
              <TableNameCell
                key={row.environmentApplicationName}
                name={row.environmentApplicationName}
                imgSrc={row.icon}
                isRowActive={params.isRowActive}
                {...column?.props}
              />
            );
          default:
            return (
              <GenericTableCell
                key={row[column?.name || column]}
                value={
                  column?.shouldHideValue
                    ? ''
                    : isDateString(row[column?.name || column])
                      ? formatDate(row[column?.name || column])
                      : row[column?.name || column]
                }
                styles={column?.styles || {}}
                tooltipProps={column?.tooltipProps}
                {...column?.props}
              />
            );
        }
      },
      [row],
    );

    const onRowClick = useCallback(
      event => {
        if (hasSelection) {
          onChangeMarkedItem(row);

          return;
        }

        if (source === 'apps') {
          onToggleOpen(event);
        }

        if (source === 'devices') {
          navigateToDeviceDetails(row.id);
        }
      },
      [hasSelection, onChangeMarkedItem, row, onToggleOpen, source, navigateToDeviceDetails],
    );

    return (
      <>
        <TableRow
          ref={ref}
          onClick={onRowClick}
          sx={{
            backgroundColor: isRowActive ? '#ecefed' : '#4B4B4B',
            opacity: isRowActive ? 0.7 : 1,
            color: isRowActive ? '#000' : '#DDDDDD',
            cursor: hasSelection || isClickable ? 'pointer' : 'default',
          }}>
          {columnData.map(column => {
            if (renderCustomCell) {
              return renderCustomCell(column, row);
            }

            return renderCell(column, {
              isRowActive,
            });
          })}
          <ConditionComponent
            condition={!ActionComponent && hasActions}
            renderElement={
              <TableCell>
                <Stack direction="row" justifyContent="center">
                  <IconButton
                    aria-label="expand row"
                    onClick={onToggleOpen}
                    selected={open}
                    color="#DDDDDD"
                    shape="rounded">
                    <Iconify
                      icon={
                        open ? 'iconamoon:arrow-up-2-duotone' : 'iconamoon:arrow-right-2-duotone'
                      }
                      color="#DDDDDD"
                      width={24}
                      height={24}
                    />
                  </IconButton>
                </Stack>
              </TableCell>
            }
          />
          <ConditionComponent
            condition={!!ActionComponent && hasActions}
            renderElement={<ActionComponent />}
          />
        </TableRow>
        {open && (
          <ExpandedRow
            key={row.id}
            source={source}
            applicationId={row.id}
            columnsOrder={columnData}
            onClick={rowClickHandler}
          />
        )}
      </>
    );
  },
);

export default memo(Row);
