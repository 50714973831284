import CircularProgress from '@mui/material/CircularProgress';
import MuiIconButton from '@mui/material/IconButton';
import { generateButtonStyles } from './Button/Button';

const IconButton = ({ loading, children, hasHover = true, hasActive = true, ...props }) => (
  <MuiIconButton
    {...props}
    disableRipple
    sx={theme =>
      generateButtonStyles({
        theme,
        styles: props.styles,
        variant: props.variant,
        hasHover,
        hasActive,
      })
    }
    hasHover={hasHover}
    hasActive={hasActive}>
    {loading ? <CircularProgress color="inherit" size={16} /> : children}
  </MuiIconButton>
);

export default IconButton;
