export const datePickerOptions = [
  {
    value: 5,
    label: 'Last 5 days',
  },
  {
    value: 10,
    label: 'Last 10 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
];
