import { useCallback, useState } from 'react';

const Image = ({ src, placeholderUrl, alt, ...restProps }) => {
  const [imageError, setImageError] = useState(false);
  const handleImageError = useCallback(() => {
    setImageError(true);
  }, [setImageError]);

  return (
    <img
      {...restProps}
      src={imageError || !src ? placeholderUrl : src}
      onError={handleImageError}
      alt={alt}
    />
  );
};

export default Image;
