import Iconify from '../Iconify';
import { colors } from '../../theme/palette/darkPalette';
import Button from '../Button';

const DeleteButton = ({ onClick, children, hasIcon = true, styles }) => (
  <Button
    variant="text"
    onClick={onClick}
    hasHover={false}
    hasActive={false}
    {...(hasIcon
      ? {
          startIcon: <Iconify icon="ic:round-minus" color={colors.red} width={16} height={16} />,
        }
      : {})}
    styles={{
      color: colors.red,
      maxWidth: '90px',
      ...styles,
    }}>
    {children}
  </Button>
);

export default DeleteButton;
