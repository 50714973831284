import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const DeleteButton = styled(Button)({
  backgroundColor: '#DE312B',
  color: '#fff',
  padding: '8px 20px',
  ':hover': {
    backgroundColor: '#DE312B',
    color: '#fff',
  },
  ':active': {
    backgroundColor: '#DE312B',
    color: '#fff',
  },
});

export default DeleteButton;
