import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { pagesText } from '../../constants/pagesText';
import { DEVICE_TAGS_QUERY_KEY, DEVICES_QUERY_KEY } from '../../constants/query';
import TagService from '../../services/tag.service';
import { useEntityManager } from '../../context/EntityManagerContext';

const useRemoveDeviceTag = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { customerEnvId } = useEntityManager();

  return useMutation(TagService.removeTag, {
    onMutate: async newData => {
      await queryClient.cancelQueries({
        queryKey: DEVICES_QUERY_KEY,
      });

      const previousDevices = queryClient.getQueryData(DEVICES_QUERY_KEY);

      queryClient.setQueryData([DEVICES_QUERY_KEY, newData.query, customerEnvId], prevData => {
        const updatedPages = prevData.pages.map(page => {
          const updatedDevices = page.devices.map(device => {
            if (device.id === newData.deviceId) {
              return {
                ...device,
                tagName: null,
              };
            }
            return device;
          });

          return {
            ...page,
            devices: updatedDevices,
          };
        });

        return {
          ...prevData,
          pages: updatedPages,
        };
      });

      await queryClient.invalidateQueries([DEVICE_TAGS_QUERY_KEY]);

      return { previousDevices };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData(DEVICES_QUERY_KEY, context.previousDevices);
      enqueueSnackbar(pagesText.somethingWentWrong, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
    onSuccess: () => {
      enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceTag, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useRemoveDeviceTag;
