import { memo, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Iconify from './Iconify';
import { pagesText } from '../constants/pagesText';

const CopyButton = ({ copyText }) => {
  const { enqueueSnackbar } = useSnackbar();
  const onCopy = useCallback(() => {
    if (!copyText) return;

    navigator.clipboard.writeText(copyText).then(() => {
      enqueueSnackbar(pagesText.copyTextMessage, { variant: 'success' });
    });
  }, [copyText, enqueueSnackbar]);

  return (
    <IconButton
      disabled={!copyText}
      onClick={onCopy}
      sx={{
        '&:hover': {
          backgroundColor: 'unset',
        },
      }}>
      <Iconify
        icon="ic:outline-content-copy"
        color={!copyText ? '' : '#fff'}
        width={24}
        height={24}
      />
    </IconButton>
  );
};

export default memo(CopyButton);
