import { forwardRef, memo } from 'react';
import { styled } from '@mui/material/styles';
import MuiMenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Iconify from './Iconify';

const StyledMenuItem = styled(MuiMenuItem, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'styles',
})(({ theme, selected, styles, hasHover, hasActive }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 400,
  color: '#fff',
  ...(selected
    ? {
        backgroundColor: '#2f3b40',
        '& svg': {
          color: theme.palette.action.active,
        },
      }
    : {}),
  ':hover': {
    backgroundColor: hasHover ? theme.palette.action.hover : 'transparent',
    color: hasHover ? theme.palette.action.textHoverColor : '#fff',
    '& svg': {
      color: hasHover ? theme.palette.action.active : 'initial',
    },
  },
  ':active': {
    backgroundColor: hasActive ? theme.palette.action.focus : 'transparent',
    color: hasActive ? theme.palette.action.textFocusColor : '#fff',
    '& svg': {
      color: hasActive ? theme.palette.action.active : 'initial',
    },
  },
  ...styles,
}));

const MenuItem = forwardRef(
  (
    {
      onClick,
      icon,
      text,
      selected,
      styles,
      children,
      hasHover = true,
      hasActive = true,
      ...props
    },
    ref,
  ) => (
    <StyledMenuItem
      onClick={onClick}
      selected={selected}
      styles={styles}
      disableRipple
      ref={ref}
      hasHover={hasHover}
      hasActive={hasActive}
      {...props}>
      {icon && (
        <ListItemIcon>
          <Iconify icon={icon} color="#fff" width={24} height={24} />
        </ListItemIcon>
      )}
      {text}
      {children}
    </StyledMenuItem>
  ),
);

export default memo(MenuItem);
