import { useQueryClient } from 'react-query';
import DownloadsService from '../services/downloads.service';
import { DOWNLOADS_QUERY_KEY } from '../constants/query';
import { useEntityManager } from '../context/EntityManagerContext';

const useUpdateResourceStatus = () => {
  const queryClient = useQueryClient();
  const { customerEnvId } = useEntityManager();
  const updateResourceStatus = ({ downloadId, resource }) => {
    const selectedResource = resource.find(resource => resource.id === downloadId);

    if (selectedResource.seen) return;
    DownloadsService.updateDownloadResourceStatus({ downloadId }).then(updatedResource => {
      queryClient.setQueryData([DOWNLOADS_QUERY_KEY, customerEnvId], prevData =>
        prevData.map(data => (data.id === downloadId ? updatedResource : data)),
      );
    });
  };

  return { updateResourceStatus };
};

export default useUpdateResourceStatus;
