export default class CustomerIdService {
  static getId() {
    const customerId = localStorage.getItem('customerId');
    if (customerId === null || customerId === undefined) return null;

    return Number(localStorage.getItem('customerId'));
  }
  static setId(customerId) {
    return localStorage.setItem('customerId', customerId);
  }
  static removeId() {
    return localStorage.removeItem('customerId');
  }
}
