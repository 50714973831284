import { useLocation } from 'react-router-dom';

const getEmail = slicedUrl => {
  const regex = /(?:&|^)email=([^&]*)/;
  const encodedUrl = encodeURIComponent(slicedUrl);
  const decodedURL = decodeURIComponent(encodedUrl.replace('%2B', '+'));
  const decodedEmailArray = decodedURL.match(regex);

  return decodedEmailArray ? decodedEmailArray[1] : '';
};

const useSearchParams = () => {
  const location = useLocation();
  const slicedUrl = location.search.slice(1);
  const urlSearchParams = new URLSearchParams(slicedUrl);
  const params = Object.fromEntries(urlSearchParams.entries());
  const email = getEmail(slicedUrl);

  return {
    ...params,
    email,
  };
};

export default useSearchParams;
