import { memo } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/material/Box';

const Loader = ({ icon, size = '92px' }) => (
  <CssVarsProvider>
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <CircularProgress
        sx={{
          '--CircularProgress-size': size,
          '& .MuiCircularProgress-track': {
            stroke: '#698995',
          },
          '& .MuiCircularProgress-progress': {
            stroke: '#87c8e0',
          },
        }}>
        {icon}
      </CircularProgress>
    </Box>
  </CssVarsProvider>
);

export default memo(Loader);
