import { memo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { environmentIdMenuOptions } from './constants';
import { pagesText } from '../../../constants/pagesText';
import GeneralTextField from '../../profile/general/GeneralTextField';

const StyledTextField = styled(GeneralTextField)({
  '& svg': {
    fill: '#fff',
  },
});

const EnvironmentIdMenu = props => (
  <StyledTextField
    select
    label={pagesText.environments.applicationManagementPlaceholder}
    helperText={props.error}
    {...props}>
    {environmentIdMenuOptions.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))}
  </StyledTextField>
);

export default memo(EnvironmentIdMenu);
