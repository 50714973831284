import { useCallback, useMemo } from 'react';
import { parseQueryToApiQuery } from '../utils/query.utils';
import ApplicationsService from '../services/applications.service';
import useInfiniteFetch from './useInfiniteFetch';
import { APPLICATIONS_QUERY_KEY } from '../constants/query';
import useQueryParams from './useQueryParams';
import { useEntityManager } from '../context/EntityManagerContext';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';

const useFetchApps = ({ hasQueryParams = true }) => {
  const { customerEnvId } = useEntityManager();
  const {
    queryObject: { filters = {}, searchValue: initialSearchValue, ...rest },
  } = useQueryParams();

  const query = useMemo(
    () => parseQueryToApiQuery({ filters, searchValue: initialSearchValue, ...rest }),
    [filters, initialSearchValue, rest],
  );

  const fetchFunction = useCallback(
    ({ limit, page }) =>
      ApplicationsService.getApplications({
        customerEnvironmentId: customerEnvId,
        query,
        limit,
        page,
      }),
    [customerEnvId, query],
  );

  return useInfiniteFetch({
    queryKey: hasQueryParams
      ? [APPLICATIONS_QUERY_KEY, query, customerEnvId]
      : [APPLICATIONS_QUERY_KEY, '', customerEnvId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'applications',
  });
};

export default useFetchApps;
