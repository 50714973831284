import { isObjectEmpty } from './boolean.util';

export const mapCellValue = value => {
  if (value === null) return '-';
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';

  return value;
};

export const checkHasFilters = (filters, nestedObjectLevel = 3) => {
  if (isObjectEmpty(filters)) return false;

  const filtersArray = Object.values(filters);
  const flatFilters = filtersArray.flat(nestedObjectLevel);

  return flatFilters.some(({ value }) => !!value);
};
