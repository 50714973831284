import { memo } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { pagesText } from '../../../constants/pagesText';
import Button from '../../../components/Button';
import GeneralTextField from '../../profile/general/GeneralTextField';
import CopyButton from '../../../components/CopyButton';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const DeviceLoggingApiKeyDialog = ({ isOpen, onClose, onSuccess, apiKey }) => (
  <Dialog open={isOpen} onClose={onClose}>
    <DialogTitle>{pagesText.deviceApiKeyDialog.title}</DialogTitle>
    <StyledDialogContent>
      <Typography>
        {pagesText.deviceApiKeyDialog.helpText}
        <Link
          href="https://www.appcontrol.ai/faq"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: '#fff',
            textDecoration: '#fff underline',
          }}>
          https://www.appcontrol.ai/faq
        </Link>
      </Typography>
      <Stack direction="row" gap={2}>
        <Box width="100%">
          <GeneralTextField
            value={apiKey || pagesText.deviceApiKeyDialog.noKeyFound}
            readOnly={!apiKey}
            FieldComponent={() => <CopyButton copyText={apiKey} />}
            wrapperProps={{
              alignItems: 'center',
            }}
          />
        </Box>
      </Stack>
      <Typography>{pagesText.deviceApiKeyDialog.regenerateTextMessage}</Typography>
    </StyledDialogContent>
    <DialogActions>
      <Button variant="text" styles={{ color: '#B5B5B5' }} onClick={onClose}>
        {pagesText.deviceApiKeyDialog.closeButton}
      </Button>
      <Button variant="contained" onClick={onSuccess}>
        {!apiKey
          ? pagesText.deviceApiKeyDialog.generateButton
          : pagesText.deviceApiKeyDialog.regenerateButton}
      </Button>
    </DialogActions>
  </Dialog>
);

export default memo(DeviceLoggingApiKeyDialog);
