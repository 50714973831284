import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors } from '../../theme/palette/darkPalette';

const checkShouldForwardProp = prop => {
  switch (prop) {
    case 'mediumScreen':
    case 'largeScreen':
    case 'smallScreen':
    case 'isFiltering':
    case 'hasSorting':
      return false;

    default:
      return true;
  }
};

const TableHeadTypography = styled(Typography, {
  shouldForwardProp: prop => checkShouldForwardProp(prop),
})(({ theme, largeScreen, mediumScreen, smallScreen, hasSorting = false }) => ({
  color: hasSorting ? colors.green : '#fff',
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(12),
  ...(largeScreen
    ? {
        fontSize: theme.typography.pxToRem(10),
      }
    : {}),
  ...(mediumScreen
    ? {
        fontSize: theme.typography.pxToRem(8),
      }
    : {}),
  ...(smallScreen
    ? {
        fontSize: theme.typography.pxToRem(8),
      }
    : {}),
  lineHeight: '14px',
  textTransform: 'uppercase',
  cursor: hasSorting ? 'pointer' : 'default',
}));

export default memo(TableHeadTypography);
