import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { pagesText } from '../../constants/pagesText';
import Button from '../../components/Button';
import { TAG_DEVICES_COUNT_QUERY_KEY, TAG_DEVICES_QUERY_KEY } from '../../constants/query';
import TagService from '../../services/tag.service';
import DeleteButton from '../../components/DeleteButton';
import { PORTAL_ROUTE } from '../../constants/routes';
import { getDeviceTagIdFilterQuery } from '../../utils/devices.util';
import { colors } from '../../theme/palette/darkPalette';
import { VISIBLE_DEVICES_COUNT } from './constants';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: '#3f3f3f',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '8px !important',
    backgroundColor: '#3f3f3f',
    padding: 0,
    maxWidth: '200px',
    whiteSpace: 'pre-wrap',
  },
}));

const AlertRemoveTagDialog = ({ open, onClose, onSuccess, tagId }) => {
  const navigate = useNavigate();
  const { data: devicesCount } = useQuery(
    [TAG_DEVICES_COUNT_QUERY_KEY, tagId],
    () => TagService.getDeviceCount({ tagId }),
    {
      enabled: !!tagId,
    },
  );
  const { data: tagDevices } = useQuery(
    [TAG_DEVICES_QUERY_KEY, tagId],
    () => TagService.getDevicesByTagId({ tagId }),
    {
      enabled: !!tagId,
    },
  );

  const partialDevicesNames = useMemo(() => {
    if (!tagDevices?.devices || !tagDevices?.devices.length) {
      return '';
    }

    return tagDevices.devices
      .slice(0, VISIBLE_DEVICES_COUNT)
      .map(device => device.name)
      .join(', ');
  }, [tagDevices]);

  const navigateFilteredDevicesPage = useCallback(() => {
    navigate(`${PORTAL_ROUTE}/devices?filters=${JSON.stringify(getDeviceTagIdFilterQuery(tagId))}`);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagId, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '450px',
        },
      }}>
      <DialogTitle>{pagesText.deleteTagDialog.title}</DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: 0.25,
            fontWeight: 400,
          }}>
          {pagesText.deleteTagDialog.description}
          <Typography
            component="span"
            sx={{
              ml: 0.5,
              cursor: 'pointer',
            }}>
            <StyledTooltip
              disableHoverListener={!tagDevices?.devices || !tagDevices?.devices.length}
              title={
                <Box
                  sx={{
                    color: '#fff',
                    padding: 1,
                    textAlign: 'left',
                  }}>
                  <Typography
                    sx={{
                      textTransform: 'initial',
                    }}>
                    {partialDevicesNames}
                  </Typography>
                  {tagDevices?.devices?.length > 4 && (
                    <Typography
                      onClick={navigateFilteredDevicesPage}
                      component="span"
                      sx={{
                        textTransform: 'lowercase',
                        color: colors.green,
                        cursor: 'pointer',
                      }}>
                      {tagDevices?.devices.length - VISIBLE_DEVICES_COUNT} more
                    </Typography>
                  )}
                </Box>
              }>
              <Typography
                component="span"
                sx={{
                  color: colors.green,
                  cursor: 'pointer',
                }}>
                {devicesCount} devices
              </Typography>
            </StyledTooltip>
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={onClose}
          styles={{
            color: '#B5B5B5',
          }}>
          {pagesText.deleteTagDialog.no}
        </Button>
        <DeleteButton onClick={onSuccess}>{pagesText.deleteTagDialog.yes}</DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertRemoveTagDialog;
