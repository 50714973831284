import { Suspense, useMemo } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Loader } from '../components';
import { PASSWORD_RESET_ROUTE, PORTAL_ROUTE } from '../constants/routes';
import useSearchParams from '../hooks/useSearchParams';
import useSubscribeStorage from '../hooks/useSubscribeStorage';

export default function UnauthenticatedLayout() {
  const authenticated = useSubscribeStorage('refreshToken');
  const location = useLocation();
  const { email, new: isNew, customerId, token } = useSearchParams();

  const newUser = useMemo(() => {
    if (isNew) return isNew === 'True';

    return null;
  }, [isNew]);

  if (newUser !== undefined && newUser !== null && !authenticated) {
    return <Navigate to="/email-invitation" state={{ email, customerId, isNew: newUser, token }} />;
  }

  let { from } = location.state || {};
  const destination = (from && from.pathname) || `${PORTAL_ROUTE}/apps`;

  if (location.pathname === PASSWORD_RESET_ROUTE && location.key === 'default') {
    return (
      <Navigate
        to={{
          pathname: location.pathname,
          search: location.search,
        }}
      />
    );
  }

  if (authenticated && location.pathname !== PASSWORD_RESET_ROUTE) {
    return (
      <Navigate
        to={{
          pathname: destination,
          search: location.search,
        }}
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
}
