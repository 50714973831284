import { styled } from '@mui/material/styles';
import Button from './Button';

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: '350px',
  height: '44px',
  backgroundColor: theme.palette.action.active,
  padding: '0 16px',
  color: theme.palette.action.textColor,
  borderRadius: '6px',
  marginTop: theme.spacing(3.75),
  fontSize: theme.typography.pxToRem(20),
  fontWeight: 400,
  lineHeight: '24px',
  textTransform: 'uppercase',
  fontFamily: 'Inter',
  boxShadow: 'none',
}));

const AuthSubmitButton = ({ children, onClick }) => (
  <StyledButton fullWidth size="large" type="submit" variant="contained" onClick={onClick}>
    {children}
  </StyledButton>
);

export default AuthSubmitButton;
