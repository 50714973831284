import { pagesText } from '../../../constants/pagesText';

export const headings = [
  {
    title: pagesText.environments.environmentsTable.headings.environmentName,
    name: 'name',
  },
  {
    title: pagesText.environments.environmentsTable.headings.description,
    name: 'description',
  },
  {
    title: pagesText.environments.environmentsTable.headings.actions,
    name: 'actions',
  },
];

export const generateColumnData = description => {
  return [
    {
      name: 'name',
      styles: {
        wordBreak: 'break-all',
      },
      props: {
        colSpan: 3,
      },
    },
    {
      name: 'description',
      styles: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '200px',
      },
      tooltipProps: {
        text: description,
        placement: 'bottom-start',
      },
      props: {
        colSpan: 3,
      },
    },
  ];
};

export const IdField = {
  CONFIG_MANAGER: 'configManager',
  INTUNE: 'intune',
  OTHER: 'other',
};

export const environmentIdLabelsMap = {
  [IdField.CONFIG_MANAGER]: 'Support ID',
  [IdField.INTUNE]: 'AZURE Tenant ID',
  [IdField.OTHER]: 'Custom ID',
};

export const environmentIdMenuOptions = [
  {
    label: pagesText.environments.envIdMenu.configurationManager,
    value: IdField.CONFIG_MANAGER,
  },
  {
    label: pagesText.environments.envIdMenu.intune,
    value: IdField.INTUNE,
  },
  {
    label: pagesText.environments.envIdMenu.other,
    value: IdField.OTHER,
  },
];

export const CONFIRM_SIGNATURE = 'I CONFIRM';
