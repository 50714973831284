import { memo } from 'react';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { pagesText } from '../../../constants/pagesText';
import GoBackButton from '../../../components/BackButton';
import Button from '../../../components/Button';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.paper}`]: {
    backgroundColor: '#4B4B4B',
    padding: `${theme.spacing(5.625)} ${theme.spacing(4.375)}`,
  },
}));

const Description = styled(Typography)({
  fontWeight: 400,
  lineHeight: '28px',
  textAlign: 'center',
});

const EnvironmentNameTypography = styled(Typography)({
  fontWeight: 700,
  lineHeight: '28px',
  textAlign: 'center',
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
});

const SwitchEnvironmentDialog = ({ open, onClose, onSuccess, environmentName }) => (
  <StyledDialog open={open} onClose={onClose} maxWidth="sm">
    <Box display="flex" flexDirection="column" gap={1} mb={2}>
      <Description>{pagesText.switchEnvironmentDialog.descriptionPartOne}</Description>
      <EnvironmentNameTypography>{environmentName}</EnvironmentNameTypography>
      <Description>{pagesText.switchEnvironmentDialog.descriptionPartTwo}</Description>
    </Box>
    <StyledDialogActions>
      <GoBackButton onClick={onClose}>
        {pagesText.switchEnvironmentDialog.cancelButton}
      </GoBackButton>
      <Button onClick={onSuccess} variant="outlined">
        {pagesText.switchEnvironmentDialog.confirmButton}
      </Button>
    </StyledDialogActions>
  </StyledDialog>
);

export default memo(SwitchEnvironmentDialog);
