import { Fragment, memo, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { handleUrlDownload, handleZipFileDownload } from '../utils/download.util';
import IconButton from './IconButton';
import Iconify from './Iconify';
import DownloadApplicationsMenu from './DownloadApplicationsMenu';
import DownloadsService from '../services/downloads.service';
import { pagesText } from '../constants/pagesText';

const DownloadActions = ({ policies, versionId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [securityAnchorEl, setSecurityAnchorEl] = useState(null);

  const onSecurityClose = useCallback(() => {
    setSecurityAnchorEl(null);
  }, [setSecurityAnchorEl]);

  const onDownloadSecurityAllCatalog = useCallback((event) => {
    event.stopPropagation();
    DownloadsService.getSecurityAllPolicyDownloadUrl({ environmentId: versionId }).then(
      response => {
        handleZipFileDownload(response, 'all-policies');
        onSecurityClose();
      },
    );
  }, [versionId, onSecurityClose]);

  const onSecurityMenuOpen = useCallback(
    event => {
      event.stopPropagation();
      setSecurityAnchorEl(event.currentTarget);
    },
    [setSecurityAnchorEl],
  );

  const onDownloadSecurityCatalog = useCallback(
    source => {
      const { resourceId, key } = policies[source] || {};
      if (!resourceId || !key) return;

      DownloadsService.getSecurityDownloadUrl({
        resourceId,
        key,
      })
        .then(url => {
          handleUrlDownload(url);
          onSecurityClose();
        })
        .catch(err => {
          enqueueSnackbar(err || pagesText.somethingWentWrong, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        });
    },
    [policies, onSecurityClose, enqueueSnackbar],
  );

  return (
    <Fragment>
      <IconButton
        aria-label="expand row"
        onClick={onSecurityMenuOpen}
        selected={!!securityAnchorEl}
        color="#fff">
        <Iconify icon="material-symbols:download" color="#fff" width={24} height={24} />
      </IconButton>
      <DownloadApplicationsMenu
        anchorEl={securityAnchorEl}
        onClose={onSecurityClose}
        onDownloadSecurityCatalog={onDownloadSecurityCatalog}
        onDownloadSecurityAllCatalog={onDownloadSecurityAllCatalog}
        originStyles={{
          transformOrigin: { horizontal: 'left', vertical: 'top' },
          anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        }}
      />
    </Fragment>
  );
};

export default memo(DownloadActions);
