import { pagesText } from '../../constants/pagesText';
import EnvironmentGuard from '../../components/EnvironmentGuard';
import Page from '../../components/Page';
import DevicesTable from './DevicesTable';

const Devices = () => {
  return (
    <Page title={pagesText.dashboard.title}>
      <EnvironmentGuard>
        <DevicesTable />
      </EnvironmentGuard>
    </Page>
  );
};

export default Devices;
