import { memo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '../../../components/Button';
import { pagesText } from '../../../constants/pagesText';
import { colors } from '../../../theme/palette/darkPalette';

const DeleteEnvironmentDialog = ({ open, onClose, onSuccess }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      sx: {
        maxWidth: '450px',
      },
    }}>
    <DialogTitle>{pagesText.deleteEnvironment.title}</DialogTitle>
    <DialogContent>
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '20px',
          letterSpacing: 0.25,
          fontWeight: 400,
        }}>
        {pagesText.deleteEnvironment.description}
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={onClose}>
        {pagesText.deleteEnvironment.cancelButton}
      </Button>
      <Button
        variant="text"
        onClick={onSuccess}
        sx={{
          color: colors.red,
        }}>
        {pagesText.deleteEnvironment.confirmButton}
      </Button>
    </DialogActions>
  </Dialog>
);

export default memo(DeleteEnvironmentDialog);
