import Paper from '@mui/material/Paper';
import MuiTableContainer from '@mui/material/TableContainer';

const TableContainer = ({ children, styles, ...props }) => (
  <MuiTableContainer
    square
    component={Paper}
    elevation={0}
    sx={{
      maxHeight: 'calc(100vh - 216px)',
      overflowY: 'auto',
      '& .MuiTableCell-stickyHeader': {
        backgroundColor: '#3f3f3f',
      },
      '&::-webkit-scrollbar': {
        display: 'block',
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#9ca3af',
        borderRadius: '16px',
      },
      ...styles,
    }}
    {...props}>
    {children}
  </MuiTableContainer>
);

export default TableContainer;
