import { memo } from 'react';
import FilterField from './FilterField';

const FiltersDropdown = ({
  fieldIndex,
  sectionIndex,
  source,
  selectedValue,
  onChangeFilter,
  label,
  children,
  disabled,
}) => (
  <FilterField
    disabled={disabled}
    source={source}
    label={label}
    selectedValue={selectedValue}
    onChangeFilter={onChangeFilter}
    fieldIndex={fieldIndex}
    sectionIndex={sectionIndex}
    select>
    {children}
  </FilterField>
);

export default memo(FiltersDropdown);
