import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableNameCell from './TableNameCell';
import Skeleton from '../Skeleton';

const LoadingRow = ({ colSpan = 1, columnCount, cellStyles = {} }) => (
  <>
    {Array.from({ length: columnCount }).map((item, index) => {
      if (index === 0) {
        return <TableNameCell sx={cellStyles} key={index} loading={true} />;
      }

      if (columnCount - 1 === index) {
        return (
          <TableCell colSpan={colSpan} key={index} scope="row" sx={cellStyles}>
            <Stack direction="row" justifyContent="center">
              <Skeleton variant="rounded" width={40} height={40} />
            </Stack>
          </TableCell>
        );
      }

      return (
        <TableCell colSpan={colSpan} key={index} scope="row" sx={cellStyles}>
          <Skeleton animation="pulse" variant="text" height={22} />
        </TableCell>
      );
    })}
  </>
);

export default LoadingRow;
