import { forwardRef, memo } from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import TableHeading from '../../../components/Table/TableHeading';
import LoadingRows from '../../../components/Table/LoadingRows';
import Row from '../../../components/Table/Row';
import NoDataCell from '../../../components/NoDataCell';
import { pagesText } from '../../../constants/pagesText';
import { generateColumnData, headings } from './constants';
import Button from '../../../components/Button';
import IconButton from '../../../components/IconButton';
import Iconify from '../../../components/Iconify';
import { colors, WARNING } from '../../../theme/palette/darkPalette';
import AlertPopup from '../../../components/AlertPopup';
import AlertDescription from '../../../components/AlertDescription';
import TableContainer from '../../../components/Table/TableContainer';

const EnvironmentsList = forwardRef(
  (
    {
      isLoading,
      isFetching,
      environments,
      page,
      rowsPerPage,
      selectedEnvId,
      onSelectEnvironment,
      onEditEnvironment,
      onDeleteEnvironment,
      hasCustomer,
      onLoggingApiKeyClick,
    },
    ref,
  ) => (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: 2,
        }}>
        <TableContainer
          styles={{
            height: 'auto',
            maxHeight: 'calc(100vh - 400px)',
            overflowY: 'auto',
          }}>
          <Table
            stickyHeader
            sx={{
              tableLayout: 'fixed',
            }}>
            <TableHeading
              headings={headings}
              hasFilter={false}
              hasHeaderCorner
              hasActions={false}
              listCellColSpan={3}
              listCellStyles={{
                top: '0px',
              }}
            />
            <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
              {isLoading ? (
                <LoadingRows cellColSpan={4} columnCount={3} rowCount={5} />
              ) : (
                <>
                  {!!environments?.length &&
                    environments.map((row, index) => (
                      <Row
                        ref={index === environments.length - 1 ? ref : null}
                        hasSelectOption
                        key={row?.id}
                        row={row}
                        columnData={generateColumnData(row.description)}
                        hasActions
                        ActionComponent={() => (
                          <TableCell colSpan={3}>
                            <Stack direction="row" justifyContent="start" gap={2}>
                              <IconButton
                                disabled={isLoading}
                                variant="text"
                                onClick={event => {
                                  event.stopPropagation();
                                  onLoggingApiKeyClick({
                                    activeEnvironmentId: row.id,
                                    deviceLoggingApiKey: row.deviceLoggingApiKey,
                                  });
                                }}>
                                <Iconify
                                  icon="fluent:lock-closed-key-24-regular"
                                  width={24}
                                  height={24}
                                  color={WARNING.main}
                                />
                              </IconButton>
                              <IconButton
                                disabled={isLoading}
                                variant="text"
                                onClick={event => {
                                  event.stopPropagation();
                                  onEditEnvironment(row.id);
                                }}>
                                <Iconify
                                  icon="ic:baseline-edit"
                                  color="#a2a2a2"
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                              <IconButton
                                disabled={isLoading}
                                variant="text"
                                onClick={event => {
                                  event.stopPropagation();
                                  onDeleteEnvironment(row.id);
                                }}>
                                <Iconify
                                  icon="material-symbols-light:delete-outline"
                                  color={colors.red}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                              <Button
                                variant="outlined"
                                disabled={selectedEnvId === row.id}
                                onClick={event => {
                                  event.stopPropagation();
                                  onSelectEnvironment(row.id);
                                }}
                                sx={{
                                  minWidth: 78,
                                }}>
                                {selectedEnvId === row.id
                                  ? pagesText.environments.environmentsTable.action.selected
                                  : pagesText.environments.environmentsTable.action.select}
                              </Button>
                            </Stack>
                          </TableCell>
                        )}
                      />
                    ))}
                  {!isLoading && isFetching && <LoadingRows columnCount={3} rowCount={5} />}
                  {!environments?.length && !!hasCustomer && (
                    <NoDataCell colSpan={9}>{pagesText.noDataFound}</NoDataCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!hasCustomer && !environments?.length && !isLoading && (
          <Box mt={1} mb={1}>
            <AlertPopup title={pagesText.companyList.noCompanyAlert.title}>
              <AlertDescription>
                {pagesText.companyList.noCompanyAlert.description}
              </AlertDescription>
              <AlertDescription>{pagesText.appsStarterPopup.extraDescription}</AlertDescription>
            </AlertPopup>
          </Box>
        )}
      </Paper>
    </>
  ),
);

export default memo(EnvironmentsList);
