import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { subDays } from 'date-fns';
import Typography from '@mui/material/Typography';
import { DEVICES_DASHBOARD_QUERY_KEY, ENVIRONMENT_NAME_QUERY_KEY } from '../../constants/query';
import DeviceService from '../../services/device.service';
import { reportedDevicesMap } from './constants';
import { pagesText } from '../../constants/pagesText';
import ApplicationsService from '../../services/applications.service';
import { useEntityManager } from '../../context/EntityManagerContext';
import { PORTAL_ROUTE } from '../../constants/routes';
import {
  getDeviceWdacStatusFilerQuery,
  getDeviceHealthStatusFilterQuery,
} from '../../utils/devices.util';
import DeviceChart from './DeviceChart';
import Loader from '../../components/Loader';
import useQueryParams from '../../hooks/useQueryParams';
import DevicesChartTagFilter from './DevicesChartTagFilter';
import DatePicker from '../../components/DatePicker';

const DevicesCharts = () => {
  const { customerEnvId } = useEntityManager();
  const { queryObject, onApplySearchParams } = useQueryParams();

  const { startDate, endDate } = useMemo(() => {
    if (!queryObject?.deviceDashboardFilters?.length)
      return {
        startDate: subDays(new Date(), 4).toISOString(),
        endDate: new Date().toISOString(),
      };

    return {
      startDate: queryObject?.deviceDashboardFilters[0],
      endDate: queryObject?.deviceDashboardFilters[1],
    };
  }, [queryObject?.deviceDashboardFilters]);
  const tagNames = queryObject?.tagNamesFilters;
  const tagIds = useMemo(
    () => (queryObject?.tagIds ? JSON.parse(queryObject?.tagIds) : []),
    [queryObject?.tagIds],
  );

  const { data: devicesData, isLoading } = useQuery(
    [DEVICES_DASHBOARD_QUERY_KEY, customerEnvId, startDate, endDate, tagNames],
    () => DeviceService.getDeviceDashboard({ TagIds: tagIds, customerEnvId, startDate, endDate }),
  );
  const navigate = useNavigate();

  const enforcementStatusChartData = useMemo(() => {
    if (!devicesData?.enforcementStatus?.length) return [];
    const hasAnyDeviceCount = devicesData.enforcementStatus.some(
      enforcementStatus => !!enforcementStatus.deviceCount,
    );
    if (!hasAnyDeviceCount) return [];

    return devicesData.enforcementStatus.map(enforcementStatus => ({
      label: enforcementStatus.status,
      value: enforcementStatus.deviceCount,
      name: enforcementStatus.status,
      enforcementStatus,
    }));
  }, [devicesData?.enforcementStatus]);
  const deviceHealthChartData = useMemo(() => {
    if (!devicesData?.deviceHealth?.length) return [];
    const hasAnyDeviceCount = devicesData.deviceHealth.some(
      deviceHealth => !!deviceHealth.deviceCount,
    );
    if (!hasAnyDeviceCount) return [];

    return devicesData.deviceHealth.flatMap(deviceHealth =>
      deviceHealth.deviceCount !== 0
        ? [
            {
              label: reportedDevicesMap[deviceHealth.healthStatus],
              value: deviceHealth.deviceCount,
              name: reportedDevicesMap[deviceHealth.healthStatus],
            },
          ]
        : [],
    );
  }, [devicesData?.deviceHealth]);
  const { data: environment } = useQuery([ENVIRONMENT_NAME_QUERY_KEY, customerEnvId], () =>
    ApplicationsService.getEnvironment(customerEnvId),
  );

  const wdacDateRange = useMemo(() => {
    if (!startDate || !endDate) return null;

    const start = new Date(startDate);
    const end = new Date(endDate);

    const timeDifference = end.getTime() - start.getTime();

    const dayDifference = timeDifference / (1000 * 3600 * 24);
    if (dayDifference === 0) return 5;
    if (dayDifference < 0) return null;

    return Math.floor(dayDifference) + 1;
  }, [startDate, endDate]);

  const isEndDateToday = useMemo(() => {
    if (!endDate) return false;
    const end = new Date(endDate);
    const today = new Date();
    return end.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
  }, [endDate]);

  const onEnforcementStatusSlotClick = useCallback(
    () => {
      navigate(
        `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
          getDeviceWdacStatusFilerQuery({
            startDate,
            endDate,
            tagIds,
          }),
        )}`,
      );
      // switch (data?.label) {
      //   case 1:
      //   case 'Audit':
      //     return navigate(
      //       `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
      //         getDeviceWdacStatusFilerQuery({
      //           // status: DeviceStatus.audit,
      //           startDate,
      //           endDate,
      //           tagIds,
      //         }),
      //       )}`,
      //     );
      //   case 2:
      //   case 'Enforced':
      //     return navigate(
      //       `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
      //         getDeviceWdacStatusFilerQuery({
      //           // status: DeviceStatus.enforced,
      //           startDate,
      //           endDate,
      //           tagIds,
      //         }),
      //       )}`,
      //     );
      //   default:
      //     navigate(
      //       `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
      //         getDeviceWdacStatusFilerQuery({
      //           // status: DeviceStatus.unknown,
      //           startDate,
      //           endDate,
      //           tagIds,
      //         }),
      //       )}`,
      //     );
      // }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tagNames, endDate, endDate],
  );

  const onReportSlotClick = () => {
    // if (!data?.label) return;

    navigate(
      `${PORTAL_ROUTE}/devices?filters=${JSON.stringify(
        getDeviceHealthStatusFilterQuery({
          // status: reportedDevicesLabelToValue[data.label],
          startDate,
          endDate,
          tagIds,
        }),
      )}`,
    );
  };

  const onChangeDateRange = useCallback(
    newDateRange => {
      onApplySearchParams({ deviceDashboardFilters: newDateRange });
    },
    [onApplySearchParams],
  );

  const onApplyTagFilter = useCallback(
    (tagNames, tagIds) => {
      onApplySearchParams({ tagNamesFilters: tagNames, tagIds: JSON.stringify(tagIds) });
    },
    [onApplySearchParams],
  );

  const tagOptions = useMemo(() => {
    if (!tagIds?.length || !tagNames?.length) return [];

    return tagIds.map((tagId, index) => ({
      id: tagId,
      name: tagNames[index],
    }));
  }, [tagNames, tagIds]);

  return (
    <Stack direction="row" gap={4}>
      <Stack gap={4} flex={1}>
        <Typography
          sx={{
            fontSize: 28,
            fontWeight: 'bold',
          }}>
          {environment?.name ? `${environment?.name} dashboard` : ''}
        </Typography>
        <DevicesChartTagFilter onApplyTagFilter={onApplyTagFilter} tagOptions={tagOptions} />
        {isLoading ? (
          <Stack width="100%" justifyContent="center">
            <Loader />
          </Stack>
        ) : (
          <Stack direction="row" alignItems="stretch" gap={5} flexWrap="wrap">
            <DeviceChart
              title={pagesText.deviceDashboard.totalDeviceInEnvironment}
              data={enforcementStatusChartData}
              onItemClick={onEnforcementStatusSlotClick}
              isLoading={isLoading}
              chartStyles={{
                '& .MuiChartsLegend-root': {
                  transform: 'translateX(-20%)',
                },
              }}
            />
            <DeviceChart
              startChildren={
                <Stack alignItems="end">
                  <DatePicker
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                    onChangeDateRange={onChangeDateRange}
                    initialDateRange={wdacDateRange}
                  />
                </Stack>
              }
              title={
                isEndDateToday
                  ? pagesText.deviceDashboard.devicesOnline.replace('x', wdacDateRange)
                  : pagesText.deviceDashboard.devicesOnlineFrom.replace('x', wdacDateRange)
              }
              data={deviceHealthChartData}
              onItemClick={onReportSlotClick}
              isLoading={isLoading}
              chartStyles={{
                '& .MuiChartsLegend-root': {
                  transform: 'translateX(-10%)',
                },
              }}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default DevicesCharts;
