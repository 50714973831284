import Typography from '@mui/material/Typography';
import { memo } from 'react';

const FilterSectionTitle = ({ children }) => (
  <Typography
    sx={theme => ({
      fontSize: theme.spacing(1.75),
      fontWeight: 500,
      lineHeight: '19.25px',
      color: '#fff',
      mb: 2,
    })}>
    {children}
  </Typography>
);

export default memo(FilterSectionTitle);
