export default class TokenService {
  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }
  static setRefreshToken(refreshToken) {
    return localStorage.setItem('refreshToken', refreshToken);
  }
  static removeRefreshToken() {
    return localStorage.removeItem('refreshToken');
  }
  static removeAccessToken() {
    return localStorage.removeItem('accessToken');
  }
  static setAccessToken(accessToken) {
    return localStorage.setItem('accessToken', accessToken);
  }
  static getAccessToken() {
    return localStorage.getItem('accessToken');
  }
  static setAccessTokenLastRefresh(accessTokenLastRefresh) {
    return localStorage.setItem('accessTokenLastRefresh', accessTokenLastRefresh);
  }
  static getAccessTokenLastRefresh() {
    return localStorage.getItem('accessTokenLastRefresh');
  }
  static removeAccessTokenLastRefresh() {
    return localStorage.removeItem('accessTokenLastRefresh');
  }
}
