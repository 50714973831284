import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import environmentService from '../../services/environment.service';

const useDeleteEnvironment = refetch => {
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: values =>
      environmentService.deleteEnvironment({
        customerEnvId: values.id,
        force: values?.force || false,
      }),
    onSuccess: response => {
      if (!response.deleted) {
        enqueueSnackbar(response.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return;
      }

      if (!!refetch) {
        refetch();
      }

      enqueueSnackbar('Environment deleted successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: error => {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useDeleteEnvironment;
