import { useQuery } from 'react-query';
import { APPLICATION_QUERY_KEY } from '../../constants/query';
import ApplicationService from '../../services/application.service';

const useApplication = ({ appId }) => {
  return useQuery([APPLICATION_QUERY_KEY, appId], () =>
    ApplicationService.getApplication({ id: appId }),
  );
};

export default useApplication;
