import { Box } from '@mui/material';
import { memo } from 'react';

const Loader = ({ icon, size = '92px' }) => (
  <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
    <Box display="flex" position="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 210 265"
        version="1.1"
        id="svg8">
        <defs id="defs2">
          <linearGradient id="linearGradient902">
            <stop style={{ stopColor: '#000000', stopOpacity: '0.012' }} offset="0" id="stop898" />
            <stop
              style={{ stopColor: '#000000', stopOpacity: '0.00392157' }}
              offset="0.60341358"
              id="stop1008"
            />
            <stop
              style={{ stopColor: '#000000', stopOpacity: '0.00392157' }}
              offset="0.82512027"
              id="stop992"
            />
            <stop
              style={{ stopColor: '#000000', stopOpacity: '0' }}
              offset="0.90293288"
              id="stop996"
            />
            <stop
              style={{ stopColor: '#000000', stopOpacity: '0' }}
              offset="0.99999982"
              id="stop994"
            />
            <stop
              style={{ stopColor: '#000000', stopOpacity: '0' }}
              offset="0.99999982"
              id="stop906"
            />
            <stop style={{ stopColor: '#2166d0', stopOpacity: '1' }} offset="1" id="stop900" />
          </linearGradient>
          <linearGradient id="linearGradient1080">
            <stop style={{ stopColor: '#2166d0', stopOpacity: '1' }} offset="0" id="stop1076" />
            <stop style={{ stopColor: '#7cbddd', stopOpacity: '1' }} offset="1" id="stop1078" />
          </linearGradient>
          <linearGradient
            href="#linearGradient902"
            id="linearGradient904"
            x1="-139.02916"
            y1="2003.3258"
            x2="-182.41328"
            y2="1912.2461"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0.12567709,0,0,0.12567708,30.727966,17.554314)"
          />
          <linearGradient
            href="#linearGradient1080"
            id="linearGradient982"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0.475,0,0,0.475,38.966095,8.4736348)"
            x1="139.67674"
            y1="532.11578"
            x2="139.52257"
            y2="468.35855"
          />
        </defs>
        <style>{`
      .line1 {
        opacity:1;
        fill:none;
        stroke:url(#linearGradient904);
        stroke-width:21.375;
        stroke-linecap:butt;
        stroke-linejoin:miter;
        stroke-miterlimit:100;
        stroke-dasharray:420;
        stroke-dashoffset:0;
        stroke-opacity:1;
        animation: line1 3s ease-in-out infinite;
      }
      @keyframes line1 {
        0% { stroke-dashoffset:420; stroke-opacity:0}
        37% { stroke-dashoffset:840; stroke-opacity:1}
        70% { stroke-dashoffset:840}
        85% { stroke-opacity: 1}
        100% { stroke-dashoffset:1260; stroke-opacity:0} 
      }
      .line2 {
        opacity:1;
        fill:none;
        stroke:#76b6db;
        stroke-width:21.375;
        stroke-linecap:butt;
        stroke-linejoin:miter;
        stroke-miterlimit:100;
        stroke-dasharray:420;
        stroke-dashoffset:420;
        stroke-opacity:1;
        animation: line2 3s ease-in-out infinite;
      }
      @keyframes line2 {
        0% { stroke-dashoffset:1260; stroke-opacity: 0}
        28% { stroke-dashoffset:1260;stroke-opacity: 0}
        65% { stroke-dashoffset:840; stroke-opacity: 1}
        75% { stroke-dashoffset:840; stroke-opacity:1}
        100% { stroke-dashoffset:420; stroke-opacity: 0}
      }
      .triangle {
        fill-opacity:1;
        stroke:#000000;
        stroke-width:0;
        animation: triangle 3s ease-in-out infinite;
      }
      @keyframes triangle
      {
        0% { fill: #76b6db;}
        50% { fill: #2166d0;}
        100% { fill: #76b6db;}
      }
  `}</style>
        <g id="layer1">
          <path
            id="path864"
            className="line1"
            d="M 94.481991,221.80151 V 57.736493 L 11.26487,264.70031"
          />
          <path
            id="path942"
            className="line2"
            d="m 162.16991,173.25277 26.82382,66.58526 -73.10553,-35.06247 1.4e-4,-147.037386 39.64553,99.489506"
          />
          <path
            className="triangle"
            d="m 50.735854,261.38094 54.503466,-30.437 54.03156,30.31902 z"
            id="path847-5"
          />
        </g>
      </svg>
      <Box sx={{ position: 'absolute', top: '8px', left: '8px' }}>{icon}</Box>
    </Box>
  </Box>
);

export default memo(Loader);
