import useMediaQuery from '@mui/material/useMediaQuery';

const useTypographyMediaQuery = () => {
  const mediumScreen = useMediaQuery('(min-width: 1367px) and (max-width: 1440px)');
  const largeScreen = useMediaQuery('(min-width: 1440px) and (max-width: 1920px)');
  const smallScreen = useMediaQuery('(max-width: 1367px)');

  return { mediumScreen, largeScreen, smallScreen };
};

export default useTypographyMediaQuery;
