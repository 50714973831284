import axios from 'axios';
import config from '../utils/config';
import TokenService from './token.service';
import parseError from '../utils/parseError';
import authService from './auth.service';

const instance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const accessToken = TokenService.getAccessToken();
    const accessTokenLastRefresh = TokenService.getAccessTokenLastRefresh();

    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    if (accessTokenLastRefresh) {
      config.accessTokenLastRefresh = accessTokenLastRefresh;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => res.data,
  async err => {
    const originalConfig = err.config;

    if (!originalConfig.url.startsWith('/auth/') && err.response) {
      // Access Token was expired but is needed for this call
      // attempt to refresh
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const accessTokenLastRefresh = localStorage.getItem('accessTokenLastRefresh');
          //first we try to get the cross-tab lock
          await navigator.locks.request('refresh_token', async () => {
            if (
              !originalConfig.accessTokenLastRefresh ||
              !accessTokenLastRefresh ||
              originalConfig.accessTokenLastRefresh >= accessTokenLastRefresh
            ) {
              // try refreshing the token
              instance
                .post('/auth/refresh', {
                  refreshToken: TokenService.getRefreshToken(),
                })
                .then(({ accessToken, refreshToken }) => {
                  TokenService.setAccessToken(accessToken);
                  TokenService.setAccessTokenLastRefresh(Date.now());
                  TokenService.setRefreshToken(refreshToken);
                })
                .catch(() => {
                  authService.logout();
                });
            }
          });
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(parseError(_error));
        }
      }
    }
    return Promise.reject(parseError(err));
  },
);

export default instance;
