import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 375,
  width: '100%',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 300,
  lineHeight: '16px',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

export default Description;
