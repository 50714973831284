import { useMemo } from 'react';
import MuiLinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const StyledLinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: theme.spacing(1),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#637d87',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.spacing(1),
    backgroundColor: '#87C8E0',
  },
}));

const LinearProgressBar = ({ progress, totalCount }) => {
  const normalizedProgress = useMemo(() => (progress / totalCount) * 100, [progress, totalCount]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        mt: 3,
        height: '40px'
      }}
    >
      <Typography
        sx={theme => ({
          fontWeight: 600,
          fontSize: theme.typography.pxToRem(14),
          lineHeight: '24px',
          color: '#8C8C8C',
          mr: 1.25
        })}
      >
        Processing
      </Typography>
      <Typography
        sx={theme => ({
          fontWeight: 300,
          fontSize: theme.typography.pxToRem(12),
          lineHeight: '24px',
          color: '#8C8C8C',
          mr: 1.75
        })}
      >
        {progress}/{totalCount}
      </Typography>
      <Box width="100%">
        <StyledLinearProgress value={normalizedProgress} variant="determinate" />
      </Box>
    </Stack>
  );
}

export default LinearProgressBar;
