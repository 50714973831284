import * as Yup from 'yup';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { pagesText } from '../../../constants/pagesText';
import Button from '../../../components/Button';
import GeneralTextField from '../../profile/general/GeneralTextField';
import { CONFIRM_SIGNATURE } from './constants';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const ConfirmDeviceKeyGenerationDialog = ({ isOpen, onClose, onSuccess }) => {
  const [message, setMessage] = useState('');

  const formik = useFormik(
    {
      initialValues: {
        confirmMessage: '',
      },
      validationSchema: Yup.object({
        confirmMessage: Yup.string()
          .oneOf([CONFIRM_SIGNATURE], `You must type "${CONFIRM_SIGNATURE}" exactly to proceed`)
          .required('Confirm message is required'),
      }),
      onSubmit: () => {
        onSuccess();
      },
    },
    {
      validateOnChange: true,
      validateOnBlur: true,
    },
  );

  const onChange = useCallback(
    e => {
      formik.setFieldValue('confirmMessage', e.target.value, true);
      setMessage(e.target.value);
    },
    [formik],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionProps={{
        onExit: () => {
          formik.resetForm();
        },
      }}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>{pagesText.confirmDeviceKeyRegeneration.title}</DialogTitle>
        <StyledDialogContent>
          <Typography>{pagesText.confirmDeviceKeyRegeneration.description}</Typography>
          <Typography>{pagesText.confirmDeviceKeyRegeneration.confirmActionMessage}</Typography>
          <GeneralTextField
            error={formik.touched.confirmMessage && !!formik.errors.confirmMessage}
            helperText={formik.touched.confirmMessage && formik.errors.confirmMessage}
            size="small"
            InputProps={{
              sx: {
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: 'unset',
                },
              },
            }}
            {...formik.getFieldProps('confirmMessage')}
            onChange={onChange}
          />
          <Typography>{pagesText.deviceApiKeyDialog.regenerateTextMessage}</Typography>
        </StyledDialogContent>
        <DialogActions>
          <Button variant="text" styles={{ color: '#B5B5B5' }} onClick={onClose}>
            {pagesText.confirmDeviceKeyRegeneration.cancelButton}
          </Button>
          <Button variant="contained" type="submit" disabled={message !== CONFIRM_SIGNATURE}>
            {pagesText.confirmDeviceKeyRegeneration.confirmButton}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmDeviceKeyGenerationDialog;
