import { memo, useCallback, useState, useMemo } from 'react';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { pagesText } from '../../constants/pagesText';
import DeleteButton from '../../components/Filters/DeleteButton';
import Scrollbar from '../../components/Scrollbar';
import ActiveFilterSection from '../../components/Filters/ActiveFilterSection';
import SidebarListItem from '../../components/Filters/SidebarListItem';
import SidebarFooter from '../../components/Filters/SidebarFooter';
import { defaultDeviceFilters } from '../../components/Filters/constants';
import DeviceService from '../../services/device.service';
import useSubscribeStorage from '../../hooks/useSubscribeStorage';
import TagService from '../../services/tag.service';

const sidebarList = [
  {
    title: 'Last Contact Date',
    name: 'FilterDevice',
  },
  {
    title: 'Status',
    name: 'status',
  },
  {
    title: 'WDAC Status',
    name: 'wdacDeviceStatus',
  },
  {
    title: 'Health Status',
    name: 'healthStatus',
  },
  {
    title: 'OS Version',
    name: 'osVersion',
  },
  {
    title: 'OS SKU',
    name: 'osSku',
  },
  {
    title: 'OS Type',
    name: 'osType',
  },
  {
    title: 'Log Collector Version',
    name: 'logCollectorVersion',
  },
  {
    title: 'Tag Name',
    name: 'TagName',
  },
  {
    title: 'Last Wdac Heartbeat Date',
    name: 'lastWdacHeartbeatDateFilter',
  },
];

const FilterSidebarTitle = styled(Typography)(({ theme }) => ({
  color: '#fff',
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(18),
  lineHeight: '24px',
  marginLeft: theme.spacing(3.5),
}));

const SidebarDivider = () => (
  <Divider
    ornentation="horizontal"
    sx={{
      backgroundColor: '#2C2C2C',
    }}
  />
);

const FiltersSidebar = ({ open, onClose, onApplyFilters, initialQueryFilters }) => {
  const [activeFilterSection, setActiveFilterSection] = useState(null);
  const initialFilters = useMemo(
    () => ({
      FilterDevice: initialQueryFilters?.FilterDevice || defaultDeviceFilters.FilterDevice,
      status: initialQueryFilters?.status || defaultDeviceFilters.status,
      wdacDeviceStatus:
        initialQueryFilters?.wdacDeviceStatus || defaultDeviceFilters.wdacDeviceStatus,
      healthStatus: initialQueryFilters?.healthStatus || defaultDeviceFilters.healthStatus,
      osVersion: initialQueryFilters?.osVersion || defaultDeviceFilters.osVersion,
      osSku: initialQueryFilters?.osSku || defaultDeviceFilters.osSku,
      osType: initialQueryFilters?.osType || defaultDeviceFilters.osType,
      logCollectorVersion:
        initialQueryFilters?.logCollectorVersion || defaultDeviceFilters.logCollectorVersion,
      TagName: initialQueryFilters?.TagName || defaultDeviceFilters.TagName,
      lastWdacHeartbeatDateFilter:
        initialQueryFilters?.lastWdacHeartbeatDateFilter ||
        defaultDeviceFilters.lastWdacHeartbeatDateFilter,
    }),
    [initialQueryFilters],
  );

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [filters, setFilters] = useState(initialFilters);
  const customerEnvId = useSubscribeStorage('defaultEnvironmentId');

  const onChangeActiveSidebar = useCallback(
    activeSection => {
      setActiveFilterSection(activeSection);
    },
    [setActiveFilterSection],
  );

  const onDrawerClose = useCallback(() => {
    onClose();
    setActiveFilterSection(null);
    setSelectedFilters(filters);
  }, [onClose, setActiveFilterSection, setSelectedFilters, filters]);

  const getFilterDate = useCallback((newDate, label) => {
    if (!newDate) return '';

    if (label === 'To') {
      let date = new Date(newDate);
      date.setUTCHours(23, 59, 0, 0);
      date.setDate(date.getDate() + 1);

      return date.toISOString();
    }

    let date = new Date(newDate);
    date.setDate(date.getDate() + 1);
    date.setUTCHours(0, 0, 0, 0);

    return date.toISOString();
  }, []);

  const onChangeFilterValue = useCallback(
    ({ source, fieldIndex, sectionIndex, fieldValue, label }) => {
      setSelectedFilters(prevState => {
        const newSourceData = [...prevState[source]];
        newSourceData[sectionIndex] = newSourceData[sectionIndex].map((filterField, index) => {
          if (index === fieldIndex) {
            return {
              ...filterField,
              value: source === 'FilterDevice' ? getFilterDate(fieldValue, label) : fieldValue,
            };
          }

          return filterField;
        });

        return {
          ...prevState,
          [source]: newSourceData,
        };
      });
    },
    [getFilterDate],
  );

  const onAddFilter = useCallback(() => {
    const selectedFilterField = activeFilterSection.name;
    setSelectedFilters(prevState => ({
      ...prevState,
      [selectedFilterField]: [
        ...prevState[selectedFilterField],
        ...defaultDeviceFilters[selectedFilterField],
      ],
    }));
  }, [activeFilterSection?.name, setSelectedFilters]);

  const onRemoveFilter = useCallback(
    sectionIndex => {
      const selectedFilterField = activeFilterSection.name;
      setSelectedFilters(prevState => ({
        ...prevState,
        [selectedFilterField]: prevState[selectedFilterField].filter(
          (filter, filterIndex) => filterIndex !== sectionIndex,
        ),
      }));
    },
    [activeFilterSection?.name, setSelectedFilters],
  );

  const resetFilters = useCallback(() => {
    if (!activeFilterSection) {
      setFilters(defaultDeviceFilters);
      setSelectedFilters(defaultDeviceFilters);
      return;
    }

    setFilters(prevState => ({
      ...prevState,
      [activeFilterSection.name]: defaultDeviceFilters[activeFilterSection.name],
    }));
    setSelectedFilters(prevState => ({
      ...prevState,
      [activeFilterSection.name]: defaultDeviceFilters[activeFilterSection.name],
    }));
    setActiveFilterSection(null);
  }, [activeFilterSection]);

  const onSuccess = useCallback(() => {
    if (!!activeFilterSection) {
      setFilters(prevState => ({
        ...prevState,
        [activeFilterSection.name]: selectedFilters[activeFilterSection.name],
      }));
      setActiveFilterSection(null);
      return;
    }

    onApplyFilters(selectedFilters, 'apply');
    onDrawerClose();
  }, [activeFilterSection, onApplyFilters, selectedFilters, onDrawerClose]);

  const onBackClick = useCallback(() => {
    setActiveFilterSection(null);
  }, [setActiveFilterSection]);

  const isApplyFiltersDisabled = useMemo(() => {
    if (!activeFilterSection) return false;

    const activeFilterSectionFilters = selectedFilters[activeFilterSection.name];
    if (!activeFilterSectionFilters) return true;

    return activeFilterSectionFilters.flat().every(filter => filter.value === '');
  }, [activeFilterSection, selectedFilters]);

  const checkIsFilterListItemActive = useCallback(
    name => filters[name].flat().some(item => item.value !== ''),
    [filters],
  );

  const fetchFunctionsMap = useMemo(
    () => ({
      osVersion: params =>
        DeviceService.getOsVersions({
          ...params,
          customerEnvId,
        }),
      osSku: params =>
        DeviceService.getOsSku({
          ...params,
          customerEnvId,
        }),
      osType: params =>
        DeviceService.getOsTypes({
          ...params,
          customerEnvId,
        }),
      logCollectorVersion: params =>
        DeviceService.getLogCollectorVersions({
          ...params,
          customerEnvId,
        }),
      TagIds: params =>
        TagService.getTags({
          ...params,
          customerEnvId,
        }),
    }),
    [customerEnvId],
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onDrawerClose}
      PaperProps={{
        sx: theme => ({
          backgroundColor: theme.palette.background.default,
          padding: theme.spacing(6, 0),
          width: '100%',
          maxWidth: '344px',
          backgroundImage: 'unset',
        }),
      }}>
      <Box position="relative" height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            mb: 1.5,
          }}>
          <FilterSidebarTitle>Filter By Column</FilterSidebarTitle>
          <DeleteButton
            onClick={resetFilters}
            hasIcon={false}
            styles={{
              marginRight: '28px',
            }}>
            {pagesText.filters.resetFiltersButton}
          </DeleteButton>
        </Stack>
        <SidebarDivider />
        <Scrollbar
          height="calc(100% - 80px)"
          styles={{
            padding: '0 22px',
          }}>
          {activeFilterSection ? (
            <ActiveFilterSection
              title={activeFilterSection.title}
              source={activeFilterSection.name}
              filters={selectedFilters[activeFilterSection.name]}
              onChangeFilterValue={onChangeFilterValue}
              onAddFilter={onAddFilter}
              onRemoveFilter={onRemoveFilter}
              onBackClick={onBackClick}
              fetchFunctionsMap={fetchFunctionsMap}
            />
          ) : (
            <Box display="flex" mt={2.5} flexDirection="column" gap={2.25}>
              {sidebarList.map(({ title, name }) => (
                <SidebarListItem
                  title={title}
                  isActive={checkIsFilterListItemActive(name)}
                  onClick={() => onChangeActiveSidebar({ title, name })}
                />
              ))}
            </Box>
          )}
        </Scrollbar>
        <SidebarFooter
          onClose={onDrawerClose}
          onSuccess={onSuccess}
          isApplyFiltersDisabled={isApplyFiltersDisabled}
          isActiveFilterSection={!!activeFilterSection?.name}
        />
      </Box>
    </Drawer>
  );
};

export default memo(FiltersSidebar);
