import { PORTAL_ROUTE } from '../../constants/routes';

export const initialTabs = [
  {
    label: 'Users',
    value: `${PORTAL_ROUTE}/company/users`,
  },
  {
    label: 'Invite',
    value: `${PORTAL_ROUTE}/company/invite-user`,
  },
  {
    label: 'Environments',
    value: `${PORTAL_ROUTE}/company/environments`,
  },
];
