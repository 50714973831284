export default function parseError(error) {
  if (error.response && error.response?.data) {
    return (
      error.response.data?.errors?.description ||
      error.response.data?.detail ||
      error.response.data?.message ||
      (error.response.data?.errors['Description'] &&
        error.response.data?.errors['Description'][0]) ||
      error.response.data?.title ||
      error.response.data?.toString()
    );
  }
  return error.toString();
}
