import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TabsPageLayout from '../../../components/TabsPageLayout';
import { PORTAL_ROUTE } from '../../../constants/routes';
import useDevice from '../../../hooks/deviceDetails/useDevice';
import useQueryData from '../../../hooks/useQueryData';
import { USER_QUERY_KEY } from '../../../constants/query';
import { userRoleSelector } from '../../../selectors/user';
import { Roles } from '../../../constants/role';

const AppDetails = () => {
  const { deviceId } = useParams();
  const { data: device } = useDevice({ deviceId });
  const role = useQueryData(USER_QUERY_KEY, userRoleSelector);
  const isSuperAdmin = role === Roles.superAdmin;

  const tabs = useMemo(() => {
    const allTabs = [
      {
        label: 'General',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/general`,
      },
      {
        label: 'App Results',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/app-results`,
      },
      {
        label: 'Logs',
        value: `${PORTAL_ROUTE}/devices/${deviceId}/logs`,
      },
    ];

    return isSuperAdmin ? allTabs : allTabs.filter(tab => tab.label !== 'Logs');
  }, [deviceId, isSuperAdmin]);

  return (
    <TabsPageLayout
      hasBackButton
      title={device?.device?.name}
      tabs={tabs}
      backNavigationPath={`${PORTAL_ROUTE}/apps`}
      wrapperProps={{
        sx: {
          maxWidth: '100%',
        },
      }}
      titleProps={{
        fontSize: '1.4rem',
      }}
    />
  );
};

export default AppDetails;
