import { memo } from 'react';
import Button from '../Button';

const SuccessButton = ({ onClick, disabled = false, children }) => (
  <Button
    disabled={disabled}
    size="medium"
    onClick={onClick}
    styles={{
      backgroundColor: '#7DFF92',
      color: 'black',
    }}>
    {children}
  </Button>
);

export default memo(SuccessButton);
