import useTypographyMediaQuery from '../../hooks/useTypographyMediaQuery';
import Stack from '@mui/material/Stack';
import TableHeadTypography from '../TableHeadTypography';
import { sortingMethods } from './Table';
import SortingArrow from '../SortingArrow';

const CustomCell = ({
  field,
  sortingCellField,
  sortFieldName,
  title,
  onCellSort,
  onDoubleClick,
  getCellSortMethod,
}) => {
  const { mediumScreen, largeScreen, smallScreen } = useTypographyMediaQuery();

  return (
    <Stack direction="row" gap={1} onDoubleClick={() => onDoubleClick(field)}>
      <TableHeadTypography
        hasSorting={getCellSortMethod(field, sortingCellField) !== sortingMethods.initial}
        mediumScreen={mediumScreen}
        largeScreen={largeScreen}
        smallScreen={smallScreen}>
        {title}
      </TableHeadTypography>
      <SortingArrow
        hasSorting
        onClick={() => {
          return onCellSort({
            field: field,
            sortingCellField: sortingCellField,
            sortMethod: getCellSortMethod(field, sortingCellField),
            sortFieldName: sortFieldName,
          });
        }}
        sortMethod={getCellSortMethod(field, sortingCellField)}
      />
    </Stack>
  );
};

export default CustomCell;
