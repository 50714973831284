import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Page from '../components/Page';
import { pagesText } from '../constants/pagesText';
import Button from '../components/Button';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 800,
  color: '#fff',
  lineHeight: '24px',
  letterSpacing: '0.3px',
  textAlign: 'center',
  textTransform: 'capitalize',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 400,
  color: '#fff',
  lineHeight: '16.5px',
  letterSpacing: '0.3px',
  textAlign: 'center',
}));

const GradientErrorText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(200),
  fontWeight: 900,
  background:
    'radial-gradient(334.52% 161.98% at 41.92% 50%, #7DFF92 0%, #3374D2 46.21%, #000 100%)',
  backgroundClip: 'text',
  '-webkit-background-clip': 'text',
  '-webkit-text-fill-color': 'transparent',
}));

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          maxWidth="427px">
          <Stack gap={2.25}>
            <Title>{pagesText.error.title}</Title>
            <SubTitle>{pagesText.error.description}</SubTitle>
          </Stack>
          <Box mt={5.75} mb={12.25}>
            <GradientErrorText>{pagesText.error.errorText}</GradientErrorText>
          </Box>
          <Button
            to="/"
            size="large"
            component={RouterLink}
            styles={{
              backgroundColor: '#7DFF92',
              color: 'black',
              textTransform: 'uppercase',
              minWidth: '221px',
            }}>
            {pagesText.error.button}
          </Button>
        </Box>
      </Box>
    </Page>
  );
}
