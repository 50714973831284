import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  OUTSIDE_PATH_RULES_QUERY_KEY,
  PUBLISHERS_QUERY_KEY,
  UNASIGNED_FILES_QUERY_KEY,
  WRITABLE_FOLDERS_QUERY_KEY,
} from '../../../constants/query';
import ApplicationService from '../../../services/application.service';
import { pagesText } from '../../../constants/pagesText';
import {
  EXPANDED_ROWS_LIMIT, outsideFilesColumnData, outsideFilesHeading,
  publishersColumnData,
  publishersHeadings,
  unsignedFilesColumnData,
  unsignedFilesHeadings, writableFoldersColumnData, writableFoldersHeadings,
} from './constants';
import AppDetailsTableAccordion from './AppDetailsTableAccordion';

const AppDetailsSection = () => {
  const { appId } = useParams();
  const fetchPublishers = useCallback(
    async ({ page, limit, search }) => {
      return await ApplicationService.getPublishers({
        id: appId,
        page,
        limit,
        search,
      });
    },
    [appId],
  );

  const fetchUnsignedFiles = useCallback(
    async ({ page, limit, search }) => {
      return await ApplicationService.unsignedFiles({
        id: appId,
        page,
        limit,
        search,
      });
    },
    [appId],
  );

  const fetchOutsidePathRules = useCallback(
    async ({ page, limit, search }) => {
      return await ApplicationService.outsidePathRules({
        id: appId,
        page,
        limit,
        search,
      });
    },
    [appId],
  );

  const fetchWritableFoldersFiles = useCallback(
    async ({ page, limit, search }) => {
      return await ApplicationService.userWritableFolders({
        id: appId,
        page,
        limit,
        search,
      });
    },
    [appId],
  );

  return (
    <Stack>
      <AppDetailsTableAccordion
        queryKey={[PUBLISHERS_QUERY_KEY, appId]}
        fetchFunction={fetchPublishers}
        expandedRowsLimit={EXPANDED_ROWS_LIMIT}
        title={pagesText.appDetails.detailsSection.publishers}
        source="codeSigners"
        columnData={publishersColumnData}
        headings={publishersHeadings}
      />
      <AppDetailsTableAccordion
        queryKey={[UNASIGNED_FILES_QUERY_KEY, appId]}
        fetchFunction={fetchUnsignedFiles}
        expandedRowsLimit={EXPANDED_ROWS_LIMIT}
        title={pagesText.appDetails.detailsSection.unSignedFiles}
        source="unsignedFiles"
        columnData={unsignedFilesColumnData}
        headings={unsignedFilesHeadings}
      />
      <AppDetailsTableAccordion
        queryKey={[OUTSIDE_PATH_RULES_QUERY_KEY, appId]}
        fetchFunction={fetchOutsidePathRules}
        expandedRowsLimit={EXPANDED_ROWS_LIMIT}
        title={pagesText.appDetails.detailsSection.outsidePath}
        source="files"
        columnData={outsideFilesColumnData}
        headings={outsideFilesHeading}
      />
      <AppDetailsTableAccordion
        queryKey={[WRITABLE_FOLDERS_QUERY_KEY, appId]}
        fetchFunction={fetchWritableFoldersFiles}
        expandedRowsLimit={EXPANDED_ROWS_LIMIT}
        title={pagesText.appDetails.detailsSection.writableFolder}
        source="userWriteableFolders"
        columnData={writableFoldersColumnData}
        headings={writableFoldersHeadings}
      />
    </Stack>
  );
};

export default memo(AppDetailsSection);
