import api from './api';

const USER_ENDPOINT = '/user';

const getUser = () => api.get(`${USER_ENDPOINT}/me`);

const editUser = ({ firstName, lastName, companyName, email }) =>
  api.put(`${USER_ENDPOINT}/me`, {
    firstName,
    lastName,
    companyName,
    email,
  });

const updateUserStatus = ({ userId, customerId }) =>
  api.post(`${USER_ENDPOINT}/status`, {
    userId,
    customerId,
  });

const UserService = {
  getUser,
  editUser,
  updateUserStatus,
};

export default UserService;
