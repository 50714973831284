import { useCallback } from 'react';
import useQueryData from './useQueryData';
import { CUSTOMER_QUERY_KEY, ENVIRONEMNTS_QUERY_KEY } from '../constants/query';
import { customerIdSelector } from '../selectors/customer';
import CustomerService from '../services/customer.service';
import useInfiniteFetch from './useInfiniteFetch';
import { EXPANDED_ROWS_LIMIT } from '../constants/table';

const useFetchEnvironments = () => {
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  const fetchFunction = useCallback(
    ({ page, limit }) =>
      CustomerService.getCustomerEnvironments({
        customerId,
        page,
        limit,
      }),
    [customerId],
  );

  return useInfiniteFetch({
    queryKey: [ENVIRONEMNTS_QUERY_KEY, customerId],
    fetchFunction,
    expandedRowsLimit: EXPANDED_ROWS_LIMIT,
    itemName: 'environments',
  });
};

export default useFetchEnvironments;
