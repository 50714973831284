import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '../../../components/Button';
import DeleteButton from '../../../components/DeleteButton';
import { pagesText } from '../../../constants/pagesText';
import ConditionComponent from '../../../components/ConditionComponent';

const SettingsActions = ({
  onCancel,
  onDelete,
  isEditMode,
  isSubmitDisabled = false,
  SecondaryButtonComponent,
}) => (
  <Stack direction="row" justifyContent="space-between">
    {isEditMode ? (
      <DeleteButton onClick={onDelete}>{pagesText.deleteEnvironmentButton}</DeleteButton>
    ) : (
      <Box />
    )}
    <Stack
      direction="row"
      justifyContent="end"
      gap={1.75}
      sx={{
        marginRight: 9.5,
      }}>
      <ConditionComponent
        condition={!!SecondaryButtonComponent}
        renderElement={SecondaryButtonComponent}
      />
      <Button
        disabled={isSubmitDisabled}
        variant="text"
        onClick={onCancel}
        styles={{
          color: '#B5B5B5',
          textTransform: 'capitalize',
        }}>
        Cancel
      </Button>
      <Button
        disabled={isSubmitDisabled}
        variant="outlined"
        type="submit"
        styles={{
          textTransform: 'capitalize',
        }}>
        Save
      </Button>
    </Stack>
  </Stack>
);

export default memo(SettingsActions);
