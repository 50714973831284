import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import DetailLayout from './DetailLayout';
import GeneralTextField from './GeneralTextField';
import { passwordValidation } from '../../../validations/authValidation';
import SettingsActions from './SettingsActions';
import Iconify from '../../../components/Iconify';
import { isBoolean } from '../../../utils/boolean.util';
import authService from '../../../services/auth.service';
import GeneralDetailLayout from '../../../components/GeneralDetailLayout';
import CompanySectionContainer from '../../../components/CompanySectionContainer';

const StyledForm = styled(Form)({
  width: '100%',
  maxWidth: '580px',
});

const PasswordSchema = Yup.object({
  currentPassword: Yup.string().trim().required('Current password is required'),
  password: passwordValidation.notOneOf(
    [Yup.ref('currentPassword'), null],
    'New password must be different from the current password',
  ),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
});

const MatchPasswords = () => (
  <Stack direction="row" alignItems="center">
    <Iconify icon="ic:round-check" color="#7DFF92" width={20} height={20} />
    <Iconify icon="ic:round-check" color="#7DFF92" width={20} height={20} />
  </Stack>
);

const ChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: values =>
      authService.resetPassword({
        oldPassword: values.currentPassword,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
      }),
    onSuccess: () => {
      enqueueSnackbar('Password reset successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: error => {
      enqueueSnackbar(error, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: PasswordSchema,
    onSubmit: (values, { resetForm }) => {
      mutation.mutate(values, {
        onSuccess: () => {
          resetForm();
        },
      });
    },
  });

  const onCancel = useCallback(() => {
    formik.resetForm();
  }, [formik]);

  const hasConfirmPasswordError = useMemo(
    () => formik.touched.confirmPassword && !!formik.errors.confirmPassword,
    [formik.touched.confirmPassword, formik.errors.confirmPassword],
  );

  const shouldShowMatchPasswordsIcon = useMemo(() => {
    const passwordValue = formik.getFieldProps('password').value;
    const confirmPasswordValue = formik.getFieldProps('confirmPassword').value;

    return (
      isBoolean(hasConfirmPasswordError) &&
      passwordValue &&
      confirmPasswordValue &&
      passwordValue === confirmPasswordValue &&
      !hasConfirmPasswordError
    );
  }, [hasConfirmPasswordError, formik]);

  return (
    <CompanySectionContainer>
      <GeneralDetailLayout title="Change Password">
        <FormikProvider value={formik}>
          <StyledForm autoComplete="off" onSubmit={formik.handleSubmit}>
            <DetailLayout>
              <GeneralTextField
                label="Current Password"
                type="password"
                error={formik.touched.currentPassword && !!formik.errors.currentPassword}
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                {...formik.getFieldProps('currentPassword')}
              />
              <GeneralTextField
                label="New Password"
                type="password"
                error={formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                {...formik.getFieldProps('password')}
              />
              <GeneralTextField
                label="Confirm Password"
                type="password"
                error={hasConfirmPasswordError}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                FieldComponent={shouldShowMatchPasswordsIcon ? MatchPasswords : null}
                {...formik.getFieldProps('confirmPassword')}
              />
              <SettingsActions isSubmitDisabled={!formik.dirty} onCancel={onCancel} />
            </DetailLayout>
          </StyledForm>
        </FormikProvider>
      </GeneralDetailLayout>
    </CompanySectionContainer>
  );
};

export default ChangePassword;
