import MuiMenu from '@mui/material/Menu';

const Menu = ({
  anchorEl,
  onClose,
  transformOrigin = { horizontal: 'right', vertical: 'top' },
  anchorOrigin = { horizontal: 'right', vertical: 'bottom' },
  children,
  styles,
  ...rest
}) => (
  <MuiMenu
    {...rest}
    anchorEl={anchorEl}
    id="account-menu"
    open={!!anchorEl}
    onClose={onClose}
    onClick={onClose}
    PaperProps={{
      elevation: 0,
      sx: theme => ({
        '& .MuiMenu-list': {
          padding: 0,
        },
        marginTop: theme.spacing(3),
        backgroundColor: '#202020',
        boxShadow: '0px 4px 6px 0px #64748B1F',
        border: '1px solid rgba(13, 13, 13, 1)',
        borderRadius: theme.spacing(1),
        ...styles,
      }),
    }}
    transformOrigin={transformOrigin}
    anchorOrigin={anchorOrigin}>
    {children}
  </MuiMenu>
);

export default Menu;
