import { memo } from 'react';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';

const FilteredCountRow = ({ count, styles = {}, colSpan = 8 }) => (
  <TableRow>
    <TableCell
      colSpan={colSpan}
      sx={{
        backgroundColor: '#484848 !important',
        borderTopLeftRadius: '0px !important',
        borderTopRightRadius: '0px !important',
        padding: '0 16px 8px',
        borderBottom: 'unset',
        ...styles,
      }}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          minHeight: '24px',
        }}>
        {count !== null ? `Filtered (${count})` : null}
      </Stack>
    </TableCell>
  </TableRow>
);

export default memo(FilteredCountRow);
