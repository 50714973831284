import { useQuery } from 'react-query';
import { DOWNLOADS_QUERY_KEY } from '../../constants/query';
import DownloadsService from '../../services/downloads.service';

const useFetchDownloads = ({ customerEnvId }) => {
  return useQuery(!!customerEnvId && [DOWNLOADS_QUERY_KEY, customerEnvId], () =>
    DownloadsService.getDownloadsResources({ id: customerEnvId }),
  );
};

export default useFetchDownloads;
