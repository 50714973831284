import React, { useEffect, useRef } from 'react';
import { format } from 'date-fns';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import useInfiniteFetch from '../../../hooks/useInfiniteFetch';
import Iconify from '../../../components/Iconify';
import { Loader } from '../../../components';
import { pagesText } from '../../../constants/pagesText';
import TableHeading from '../../../components/Table/TableHeading';
import Row from '../../../components/Table/Row';
import GenericTableCell from '../../../components/GenericTableCell';
import { isDateString } from '../../../utils/date.util';
import TableContainer from '../../../components/Table/TableContainer';

const AppDetailsTableAccordion = ({
  queryKey,
  fetchFunction,
  source,
  expandedRowsLimit,
  title,
  columnData,
  headings,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const isFirstTimeExpanded = useRef(false);

  const { data, lastElementRef, isLoading, isFetching, count } = useInfiniteFetch({
    queryKey,
    fetchFunction,
    expandedRowsLimit,
    itemName: source,
  });

  useEffect(() => {
    if (!isLoading && !isFetching && data?.length && !isFirstTimeExpanded.current) {
      setExpanded(true);
      isFirstTimeExpanded.current = true;
    }
  }, [isLoading, isFetching, data?.length]);

  const renderCustomCell = (column, row) => (
    <GenericTableCell
      key={row[column]}
      value={isDateString(row[column]) ? format(new Date(row[column]), 'yyyy-MM-dd') : row[column]}
      styles={{
        wordBreak: 'break-all',
      }}
    />
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(prevState => !prevState)}
      sx={{
        '&.Mui-expanded': {
          margin: 0,
          opacity: 1,
          borderTop: '1px solid #606467',
        },
      }}>
      <AccordionSummary
        expandIcon={
          <Iconify icon="iconamoon:arrow-down-2-duotone" color="#fff" width={24} height={24} />
        }>
        {title}: {count}
      </AccordionSummary>
      <AccordionDetails>
        {!isLoading && !isFetching && !data?.length && (
          <Stack
            direction="row"
            justifyContent="center"
            gap={1}
            sx={{
              backgroundColor: '#393939',
              padding: 2,
              borderRadius: 1,
            }}>
            <Typography>{pagesText.noDataFound}</Typography>
          </Stack>
        )}
        {!!isLoading && <Loader size="48px" />}
        {!isLoading && !!data?.length && (
          <TableContainer square component={Paper} elevation={0}>
            <Table stickyHeader>
              <TableHeading
                headings={headings}
                hasFilter={false}
                listCellStyles={{
                  top: '0px',
                }}
              />
              <TableBody>
                {data.map((item, index) => (
                  <Row
                    ref={index === data.length - 1 ? lastElementRef : null}
                    key={index}
                    row={item}
                    columnData={columnData}
                    hasActions={false}
                    renderCustomCell={renderCustomCell}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default AppDetailsTableAccordion;
