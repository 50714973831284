import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

export const generateButtonStyles = ({ theme, hasHover, hasActive, variant, styles }) => ({
  fontFamily: 'Inter',
  textTransform: 'capitalize',
  ...(variant === 'outlined'
    ? {
        color: '#7DFF92',
        border: '1px solid #7DFF92',
        '&:disabled': {
          borderColor: '#727272',
          color: '#727272',
        },
      }
    : {}),
  ...(variant === 'contained'
    ? {
        backgroundColor: '#7DFF92',
        color: 'black',
        '&:disabled': {
          backgroundColor: '#727272',
        },
      }
    : {}),
  ...(variant === 'text'
    ? {
        '&:disabled': {
          color: '#727272',
        },
      }
    : {}),
  ...styles,
  ...(hasHover
    ? {
        ':hover': {
          backgroundColor: variant === 'outlined' ? 'transparent' : theme.palette.action.hover,
          color: theme.palette.action.textHoverColor,
          border:
            variant === 'outlined' ? `1px solid ${theme.palette.action.textHoverColor}` : 'none',
          boxShadow: 'none',
          '& .MuiButton-startIcon': {
            '& svg': {
              color: theme.palette.action.textHoverColor,
            },
          },
        },
      }
    : {}),
  ...(hasActive
    ? {
        ':active': {
          backgroundColor: theme.palette.action.focus,
          color: theme.palette.action.textFocusColor,
          border:
            variant === 'outlined' ? `1px solid ${theme.palette.action.textFocusColor}` : 'none',
          boxShadow: 'none',
          '& .MuiButton-startIcon': {
            '& svg': {
              color: theme.palette.action.textFocusColor,
            },
          },
        },
      }
    : {}),
});

const StyledButton = styled(MuiButton, {
  shouldForwardProp: prop =>
    prop !== 'styles' && prop !== 'variant' && prop !== 'hasHover' && prop !== 'hasActive',
})(({ theme, styles, variant, hasHover, hasActive }) =>
  generateButtonStyles({
    theme,
    styles,
    variant,
    hasHover,
    hasActive,
  }),
);

const Button = ({ loading, children, hasHover = true, hasActive = true, ...props }) => (
  <StyledButton {...props} disableRipple hasHover={hasHover} hasActive={hasActive}>
    {loading ? <CircularProgress color="inherit" size={16} /> : children}
  </StyledButton>
);

export default Button;
