import api from './api';

const API_PREFIX = '/customer';
const getCustomer = ({ id }) => api.get(`${API_PREFIX}/${id}`);
const resendInvitation = ({ customerId, emails }) =>
  api.post(`${API_PREFIX}/${customerId}/users/invite/resend`, {
    emails,
  });

const inviteCustomer = ({ firstName, lastName, email, customerId }) =>
  api.post(`${API_PREFIX}/${customerId}/users/invite`, {
    firstName,
    lastName,
    email,
  });
const addCompany = ({ companyName }) =>
  api.post('/customer', {
    companyName,
  });

const editCompanyName = ({ companyName, id }) =>
  api.put(`/customer/${id}`, {
    companyName,
  });

const setupAccount = ({ customerId, password, email, firstName, lastName }) =>
  api.post(`${API_PREFIX}/setupAccount`, {
    customerId,
    password,
    email,
    fName: firstName,
    lName: lastName,
  });

const acceptInvitation = ({ customerId }) =>
  api.post(`${API_PREFIX}/${customerId}/acceptInvitation`);

const getInvitations = ({ page, limit, customerId }) =>
  api.get(`${API_PREFIX}/${customerId}/invitations`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getUsers = ({ customerId, page, limit }) =>
  api.get(`${API_PREFIX}/${customerId}/users`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

export const getCustomers = ({ query, limit, page }) =>
  api.get(`${API_PREFIX}/customers${query || ''}`, {
    params: {
      Limit: limit,
      Page: page,
    },
  });

export const getCustomerEnvironments = ({ customerId, page, limit }) =>
  api.get(`${API_PREFIX}/${customerId}/environments`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

export const validateInvitation = ({ token }) =>
  api.post(`${API_PREFIX}/invitation/validate`, {
    token,
  });

const CustomerService = {
  editCompanyName,
  getCustomer,
  resendInvitation,
  inviteCustomer,
  setupAccount,
  acceptInvitation,
  addCompany,
  getInvitations,
  getUsers,
  getCustomers,
  getCustomerEnvironments,
  validateInvitation,
};

export default CustomerService;
