import { memo } from 'react';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import { listItemSecondaryActionClasses } from '@mui/material';
import { colors } from '../../theme/palette/darkPalette';
import Iconify from '../Iconify';

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'isActive',
})(({ theme, isActive }) => ({
  padding: '8px 24px',
  cursor: 'pointer',
  borderRadius: theme.spacing(1),
  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.action.textFocusColor,
  },
  [`& .${listItemSecondaryActionClasses.root}`]: {
    right: 0,
    '& svg': {
      color: isActive ? theme.palette.action.textFocusColor : '#fff',
    },
  },
  ...(!isActive
    ? {
        ':hover': {
          backgroundColor: colors.bars,
        },
        ':active': {
          backgroundColor: colors.bars,
        },
      }
    : {}),
}));

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'isActive',
})(({ theme, isActive }) => ({
  margin: 0,
  [`& .${listItemTextClasses.primary}`]: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: isActive ? colors.green : '#fff',
    ...(!isActive
      ? {
          ':hover': {
            color: '#fff',
          },
          ':active': {
            color: '#fff',
          },
        }
      : {}),
  },
}));

const SidebarListItem = ({ title, isActive, onClick }) => (
  <StyledListItem
    onClick={onClick}
    isActive={isActive}
    secondaryAction={
      <Iconify icon="iconamoon:arrow-right-2-duotone" color="#DDDDDD" width={24} height={24} />
    }>
    <StyledListItemText primary={title} isActive={isActive} />
  </StyledListItem>
);

export default memo(SidebarListItem);
