import { useEffect } from 'react';
import { useMutation } from 'react-query';
import TokenService from '../services/token.service';
import { REFRESH_QUERY } from '../constants/query';
import AuthService from '../services/auth.service';

export default function AutoLogon({ children }) {
  const { mutate: mutateRefreshToken } = useMutation(REFRESH_QUERY, AuthService.tryRefresh, {
    onSuccess: response => {
      if (response?.refreshToken) {
        TokenService.setRefreshToken(response.refreshToken);
      } else {
        TokenService.removeRefreshToken();
      }

      if (!response?.accessToken) return;
      const accessTokenLastRefresh = Date.now();
      TokenService.setAccessToken(response.accessToken);
      TokenService.setAccessTokenLastRefresh(accessTokenLastRefresh);
    },
    onError: () => {
      TokenService.removeRefreshToken();
    },
  });

  useEffect(() => {
    const refreshToken = TokenService.getRefreshToken();
    if (refreshToken) {
      mutateRefreshToken({ refreshToken });
    }
  }, [mutateRefreshToken]);

  return children;
}
