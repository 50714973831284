import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Iconify from './Iconify';

const PasswordFieldAdornment = ({ onClick, showPassword }) => (
  <InputAdornment position="end">
    <IconButton edge="end" onClick={onClick} tabIndex={-1}>
      <Iconify
        icon={showPassword ? 'mdi:eye' : 'formkit:hidden'}
        color="#D9D6E7"
        width={24}
        height={24}
      />
    </IconButton>
  </InputAdornment>
);

export default memo(PasswordFieldAdornment);
