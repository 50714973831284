import { forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// @mui
import config from 'src/utils/config';
import useSearchParams from '../hooks/useSearchParams';
import { pagesText } from '../constants/pagesText';
import { PORTAL_ROUTE } from '../constants/routes';

// ----------------------------------------------------------------------

const StyledLink = styled(Link, {
  shouldForwardProp: prop => prop !== 'last',
})(({ theme, last }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontFamily: 'Inter',
  fontWeight: 300,
  lineHeight: '14px',
  cursor: 'pointer',
  ...(last
    ? {
        color: theme.palette.text.secondary,
      }
    : {
        color: theme.palette.mode === 'light' ? '#212121' : '#fff',
      }),
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 700,
  lineHeight: '44px',
  color: theme.palette.text.primary,
}));

const Page = forwardRef(
  (
    { children, title = '', meta = {}, breadcrumbs = [], hasTitleSection = false, ...other },
    ref,
  ) => {
    const location = useLocation();
    const theme = useTheme();
    const cleanPath = location.pathname.replace(/\/$/, '').toLowerCase();
    let queryString = '';

    const { pageIndex } = useSearchParams();

    if (pageIndex) {
      queryString = `?pageIndex=${pageIndex}`;
    }

    const canonicalUrl = `${config.publicUrl}${cleanPath}${queryString}`;

    const finalTitle = `${title} - App Control`;
    const finalDesc = meta.description || title;
    const finalImageUrl = meta.imageUrl || `${config.mainUrl}/static/logo192.png`;
    return (
      <>
        <Helmet>
          <title>{finalTitle}</title>
          <meta name="title" content={finalTitle} />
          <meta name="description" content={finalDesc} />
          <link rel="canonical" href={`${canonicalUrl}`} />

          <meta property="og:title" content={finalTitle} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:description" content={finalDesc} />
          <meta property="og:image" content={finalImageUrl} />

          <meta name="twitter:title" content={finalTitle} />
          <meta name="twitter:description" content={finalDesc} />
          <meta name="twitter:image" content={finalImageUrl} />
          <meta name="twitter:url" content={canonicalUrl} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>

        <Box ref={ref} height="100%" {...other}>
          {breadcrumbs.length > 0 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{
                '& .MuiBreadcrumbs-separator': {
                  color: '#fff',
                },
              }}>
              <StyledLink
                key="App Control"
                component={RouterLink}
                underline="none"
                color={theme.palette.primary.main}
                to={`${PORTAL_ROUTE}/apps`}>
                {pagesText.breadcrumbTitle}
              </StyledLink>
              {breadcrumbs.map(breadcrumb => {
                if (!breadcrumb.label) return null;

                if (breadcrumb.route && !!breadcrumb.route.length) {
                  return (
                    <StyledLink
                      key={breadcrumb.label}
                      component={RouterLink}
                      underline="none"
                      color={theme.palette.primary.main}
                      to={breadcrumb.route}>
                      {breadcrumb.label}
                    </StyledLink>
                  );
                }

                return (
                  <StyledLink key={breadcrumb.label} last underline="none">
                    {breadcrumb.label}
                  </StyledLink>
                );
              })}
            </Breadcrumbs>
          )}
          {title && hasTitleSection && (
            <Box mt={4}>
              <StyledTitle>{title}</StyledTitle>
            </Box>
          )}
          {children}
        </Box>
      </>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.shape({
    description: PropTypes.string,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
    }),
  ),
};

export default Page;
