import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import TagService from '../../services/tag.service';
import { DEVICE_TAGS_QUERY_KEY, DEVICES_QUERY_KEY } from '../../constants/query';
import { pagesText } from '../../constants/pagesText';
import { useEntityManager } from '../../context/EntityManagerContext';

const useRemoveListTag = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { customerEnvId } = useEntityManager();

  return useMutation(TagService.removeListTag, {
    onMutate: async newData => {
      await queryClient.cancelQueries([DEVICE_TAGS_QUERY_KEY, newData.search]);
      await queryClient.cancelQueries([DEVICES_QUERY_KEY, newData.query, customerEnvId]);
      const previousTags = queryClient.getQueryData([DEVICE_TAGS_QUERY_KEY, newData.search]);
      const previousDevices = queryClient.getQueryData([
        DEVICES_QUERY_KEY,
        newData.query,
        customerEnvId,
      ]);

      queryClient.setQueryData([DEVICE_TAGS_QUERY_KEY, newData.search], old => ({
        ...old,
        pages: [
          ...old.pages.map((page, index) => {
            if (index === old.pages.length - 1) {
              return {
                ...page,
                tags: page.tags.filter(tag => tag.id !== newData.tagId),
              };
            }
            return page;
          }),
        ],
      }));

      const shouldUpdateDevices = previousDevices.pages.some(page =>
        page.devices.some(device => device.tagId === newData.tagId),
      );
      if (shouldUpdateDevices) {
        queryClient.setQueryData([DEVICES_QUERY_KEY, newData.query, customerEnvId], prevData => {
          const updatedPages = prevData.pages.map(page => {
            const updatedDevices = page.devices.map(device => {
              if (device.tagId === newData.tagId) {
                return {
                  ...device,
                  tagId: null,
                  tagName: null,
                };
              }
              return device;
            });

            return {
              ...page,
              devices: updatedDevices,
            };
          });

          return {
            ...prevData,
            pages: updatedPages,
          };
        });
      }

      return { previousTags, previousDevices };
    },
    onError: (err, newData, context) => {
      queryClient.setQueryData([DEVICE_TAGS_QUERY_KEY, newData.search], context.previousTags);
      queryClient.setQueryData(
        [DEVICES_QUERY_KEY, newData.search, newData.tagId],
        context.previousDevices,
      );
    },
    onSuccess: () => {
      enqueueSnackbar(pagesText.devices.editTagModal.removeTagMessage, { variant: 'success' });
    },
  });
};

export default useRemoveListTag;
