import { useMemo } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import de from 'date-fns/locale/de';
import darkPalette, { colors } from '../../theme/palette/darkPalette';
import GeneralTextField from '../../pages/profile/general/GeneralTextField';

const StyledDatePicker = styled(DatePicker)({
  '& svg': {
    color: '#fff',
  },
  '& label.Mui-focused': {
    color: colors.green,
  },

  '.MuiPickersLayout-root': {
    '& svg': {
      fill: '#fff',
      color: '#fff',
    },
  },
});

const innerTheme = createTheme({
  palette: {
    background: {
      ...darkPalette.background,
    },
    text: {
      ...darkPalette.text,
    },
  },
});

const FilterDatePicker = ({
  source,
  selectedValue,
  onChangeFilter,
  fieldIndex,
  sectionIndex,
  disabled,
  label,
  type,
}) => {
  const slots = useMemo(
    () => ({
      textField: props => <GeneralTextField {...props} hasActionSlot={false} />,
    }),
    [],
  );

  return (
    <ThemeProvider theme={innerTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} de={de}>
        <StyledDatePicker
          disabled={disabled}
          label={label}
          value={selectedValue || null}
          slots={slots}
          slotProps={{
            calendarHeader: {
              sx: {
                color: 'white',
                '& svg': {
                  fill: 'white',
                  color: 'white',
                },
              },
            },
            weekDayLabel: {
              sx: {
                color: 'white',
              },
            },
            day: {
              sx: {
                ':not(.Mui-selected)': {
                  border: 'unset',
                },
                '&.Mui-selected': {
                  backgroundColor: colors.green,
                  opacity: 0.8,
                  ':hover': {
                    backgroundColor: colors.green,
                  },
                  '&:focus': {
                    backgroundColor: colors.green,
                  },
                },
              },
            },
            textField: {
              sx: {
                '& label.Mui-focused': {
                  color: colors.green,
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: colors.green,
                  },
                },
                '& ::placeholder': {
                  color: '#fff',
                },
              },
            },
          }}
          onChange={newValue =>
            onChangeFilter({
              source,
              fieldIndex,
              sectionIndex,
              fieldValue: newValue,
              type,
            })
          }
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default FilterDatePicker;
