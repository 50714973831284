import { memo } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextFieled from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Iconify from './Iconify';
import IconButton from './IconButton';

const StyledInput = styled(TextFieled, {
  shouldForwardProp: prop => prop !== 'hasCorner',
})(({ hasCorner }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#4B4B4B',
    borderRadius: hasCorner ? '8px 8px 0px 0px' : '0px',
    padding: '16px 24px',
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-root input': {
    padding: 0,
  },
}));

const SearchInputRow = ({
  searchValue,
  onChangeSearchValue,
  onResetSearch,
  hasEndAdornment,
  placeholder,
  disabled,
  cellStyles = {},
  hasCorner = true,
  colSpan = 8,
}) => (
  <TableRow
    sx={{
      backgroundColor: '#333333',
    }}>
    <TableCell
      sx={{
        backgroundColor: '#333333 !important',
        padding: 0,
        ...cellStyles,
      }}
      colSpan={colSpan}>
      <StyledInput
        hasCorner={hasCorner}
        disabled={disabled}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="material-symbols:search" color="#6B7280" width={20} height={20} />
            </InputAdornment>
          ),
          ...(hasEndAdornment
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onResetSearch}>
                      <Iconify icon="ic:outline-close" color="#6B7280" width={20} height={20} />
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {}),
        }}
        value={searchValue}
        onChange={onChangeSearchValue}
        fullWidth
        placeholder={placeholder}
      />
    </TableCell>
  </TableRow>
);

export default memo(SearchInputRow);
