import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useQueryParams from './useQueryParams';

const useResetSearchParams = () => {
  const location = useLocation();
  const { onResetSearchParams } = useQueryParams({ defaultRowsPerPage: 5 });
  const prevPathLastSegment = useMemo(() => {
    const prevPath = location.state?.prevPath || '';

    return prevPath.split('/').pop();
  }, [location?.state?.prevPath]);
  const pathLastSegment = useMemo(() => {
    const path = location.pathname || '';

    return path.split('/').pop();
  }, [location.pathname]);

  useEffect(() => {
    if (!prevPathLastSegment) return;
    if (
      (prevPathLastSegment === 'apps' && pathLastSegment === 'security') ||
      (pathLastSegment === 'apps' && prevPathLastSegment === 'security')
    )
      return;

    onResetSearchParams();
  }, [prevPathLastSegment, onResetSearchParams, pathLastSegment]);
};

export default useResetSearchParams;
