import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useQueryParams = ({ defaultRowsPerPage } = { defaultRowsPerPage: 10 }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryObject = useMemo(() => {
    const entries = Array.from(searchParams.entries());
    const { page, limit, search, filters, ...rest } = entries.reduce((acc, item) => {
      const [key, value] = item;
      if (key.toLowerCase().includes('filters')) {
        acc[key] = JSON.parse(value);
        return acc;
      }
      acc[key] = value;
      return acc;
    }, {});
    const parsedNumber = Number(page || 1);
    const rowsPerPage = Number(limit || defaultRowsPerPage);

    return {
      ...(page ? { page: parsedNumber } : {}),
      ...(rowsPerPage ? { limit: rowsPerPage } : {}),
      ...(search ? { searchValue: search } : {}),
      ...rest,
      ...(filters ? { filters } : {}),
    };
  }, [defaultRowsPerPage, searchParams]);

  const onApplySearchParams = useCallback(
    ({ filters, page, limit, searchValue, ...rest }) => {
      const entries = Array.from(searchParams.entries());
      const parsedParams = Object.entries(rest).reduce((acc, [key, value]) => {
        if (key.toLowerCase().includes('filters') && value) {
          acc[key] = JSON.stringify(value);
          return acc;
        }

        acc[key] = value;
        return acc;
      }, {});
      setSearchParams({
        ...(searchValue ? { search: searchValue } : {}),
        ...(page ? { page } : {}),
        ...(limit ? { limit } : {}),
        ...Object.fromEntries(entries),
        ...parsedParams,
        ...(filters ? { filters: JSON.stringify(filters) } : {}),
      });
    },
    [searchParams, setSearchParams],
  );

  const onResetSearchParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const changeFieldInURL = useCallback(
    fields => {
      fields.forEach(({ key, value }) => {
        if (key && value === '') {
          searchParams.delete(key);
          setSearchParams(searchParams);
          return;
        }

        searchParams.set(key, value);
        setSearchParams(searchParams);
      });
    },
    [searchParams, setSearchParams],
  );

  return { queryObject, onApplySearchParams, onResetSearchParams, changeFieldInURL };
};

export default useQueryParams;
