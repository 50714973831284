import Stack from '@mui/material/Stack';

const DetailLayout = ({ children }) => (
  <Stack
    gap={3}
    sx={{
      width: '100%',
      maxWidth: '580px'
    }}
  >
    {children}
  </Stack>
);

export default DetailLayout;