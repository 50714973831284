import { Loader } from './index';
import Iconify from './Iconify';

const AuthLoader = () => (
  <Loader
    icon={(
      <Iconify
        icon="material-symbols:lock"
        color="#87c8e0"
        width={24}
        height={24}
      />
    )}
  />
);

export default AuthLoader;