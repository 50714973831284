import { useCallback, useState } from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import imagePlaceholderSrc from '../../assets/image-placeholder.jpg';
import Image from '../Image';
import Skeleton from '../Skeleton';

const StyledImg = styled(Image)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));

const TableAppImage = ({ width = 48, height = 48, name, imgSrc, loading }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = useCallback(() => {
    setImageLoaded(true);
  }, [setImageLoaded]);

  return (
    <Stack
      alignItems="center"
      sx={{
        minWidth: `${width}px`,
        position: 'relative',
      }}>
      {(!imageLoaded || loading) && (
        <Skeleton
          animation="pulse"
          width={width}
          height={height}
          sx={theme => ({
            borderRadius: theme.spacing(1),
          })}
        />
      )}
      <StyledImg
        style={{
          display: imageLoaded && !loading ? 'block' : 'none',
          width: `${width}px`,
          height: `${height}px`,
        }}
        src={imgSrc}
        alt={name}
        onLoad={handleImageLoad}
        placeholderUrl={imagePlaceholderSrc}
      />
    </Stack>
  );
};

export default TableAppImage;
