import { IdField } from '../pages/company/environments/constants';

export const formatEnvironmentId = (type, id) => {
  switch (type) {
    case IdField.INTUNE:
      return `INTUNE-${id}=`;
    case IdField.OTHER:
      return `CUSTOM-${id}=`;
    default:
      return id;
  }
};

export const parseEnvironmentId = id => {
  if (id.startsWith('INTUNE') && id.endsWith('=')) {
    return {
      id: id.slice(7, -1),
      type: IdField.INTUNE,
    };
  }
  if (id.startsWith('CUSTOM') && id.endsWith('=')) {
    return {
      id: id.slice(7, -1),
      type: IdField.OTHER,
    };
  }

  return {
    id: id || null,
    type: id ? IdField.CONFIG_MANAGER : null,
  };
};
