import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import useFetchDownloads from '../hooks/downloads/useFetchDownloads';
import useFetchDownloadResource from '../hooks/downloads/useFetchDownloadResource';
import DownloadsService from '../services/downloads.service';
import { DOWNLOADS_QUERY_KEY } from '../constants/query';
import useUpdateDownloadResources from '../hooks/downloads/useUpdateDownloadResources';
import useSubscribeStorage from '../hooks/useSubscribeStorage';

const EntityManagerContext = createContext({});

const EntityManager = ({ children }) => {
  const [isNotificationBarInvisible, setIsNotificationBarInvisible] = useState(false);
  const queryClient = useQueryClient();
  const customerEnvId = useSubscribeStorage('defaultEnvironmentId');
  const downloadResourcesQuery = useFetchDownloads({ customerEnvId });
  const { mutate: updateDownloadResource } = useFetchDownloadResource({
    onChangeNotificationBadgeVisibility: setIsNotificationBarInvisible,
    customerEnvId,
  });
  const { mutate: updateResourcesStatus } = useUpdateDownloadResources({ customerEnvId });

  useEffect(() => {
    if (!downloadResourcesQuery?.data) return;
    const pendingResources = downloadResourcesQuery?.data.filter(
      resource => resource.status === 'pending',
    );

    pendingResources.forEach(resource => {
      updateDownloadResource({ downloadId: resource.id });
    });

    const intervalId = setInterval(() => {
      pendingResources.forEach(resource => {
        updateDownloadResource({ downloadId: resource.id });
      });
    }, 10000);

    if (intervalId && !pendingResources.length) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [updateDownloadResource, downloadResourcesQuery?.data]);

  const updateResourceStatus = useCallback(
    ({ downloadId, resources }) => {
      if (resources.length === 1 && resources[0].seen) return;
      const selectedResource = resources.find(resource => resource.id === downloadId);
      if (selectedResource.seen) return;

      DownloadsService.updateDownloadResourceStatus({ downloadId }).then(updatedResource => {
        queryClient.setQueryData([DOWNLOADS_QUERY_KEY, customerEnvId], prevData =>
          prevData.map(data => (data.id === downloadId ? updatedResource : data)),
        );
      });
    },
    [customerEnvId, queryClient],
  );

  return (
    <EntityManagerContext.Provider
      value={{
        isNotificationBarInvisible,
        onChangeNotificationBadgeVisibility: setIsNotificationBarInvisible,
        downloadResourcesQuery,
        updateResourceStatus,
        updateDownloadResource,
        updateResourcesStatus,
        customerEnvId: Number(customerEnvId),
      }}>
      {children}
    </EntityManagerContext.Provider>
  );
};

export const useEntityManager = () => useContext(EntityManagerContext);
export default EntityManager;
