import api from './api';

const API_PREFIX = '/tag';

const editDeviceTag = ({ customerEnvId, tagId, tagName, deviceId }) =>
  api.post(`${API_PREFIX}/${customerEnvId}/editDeviceTag`, {
    tagId,
    ...(tagName ? { tagName } : {}),
    deviceId,
  });

const removeTag = ({ tagId, deviceId }) => api.delete(`${API_PREFIX}/${deviceId}/tag/${tagId}`);

const getTags = ({ search, customerEnvId, limit, page }) =>
  api.get(`${API_PREFIX}/${customerEnvId}/tags`, {
    params: {
      ...(search ? { Search: search } : {}),
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const removeListTag = ({ tagId }) => api.delete(`${API_PREFIX}/${tagId}`);
const getDeviceCount = ({ tagId }) => api.get(`${API_PREFIX}/${tagId}/deviceCount`);
const getDevicesByTagId = ({ tagId }) => api.get(`${API_PREFIX}/${tagId}/devices`);

const TagService = {
  editDeviceTag,
  removeTag,
  getTags,
  removeListTag,
  getDeviceCount,
  getDevicesByTagId,
};

export default TagService;
