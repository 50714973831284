import { useQueryClient } from 'react-query';

const useQueryData = (queryKey, selector = data => data) => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData(queryKey);
  if (!queryData) return null;

  return selector(queryData);
};

export default useQueryData;
