import { useQuery } from 'react-query';
import { VALIDATE_CUSTOMER_INVITE_QUERY } from '../constants/query';
import CustomerService from '../services/customer.service';

const useValidateInvitation = ({ token }) => {
  return useQuery(VALIDATE_CUSTOMER_INVITE_QUERY, () =>
    CustomerService.validateInvitation({ token }),
  );
};

export default useValidateInvitation;
