import api from './api';

const getDownloadsResources = ({ id }) => api.get(`/customerEnvironment/${id}/downloads`);
const updateDownloadsResources = ({ id, seen }) =>
  api.patch(`/customerEnvironment/${id}/downloads`, {
    ...(typeof seen === 'boolean' ? { seen } : {}),
  });
const getDownloadResourceStatus = ({ downloadId }) => api.get(`downloads/${downloadId}`);
const getDownloadFile = ({ s3Key, downloadId }) =>
  api.get(`/downloads/${downloadId}/resource/${s3Key}`);
const getSecurityDownloadUrl = ({ resourceId, key }) =>
  api.get(`/application/${resourceId}/resource/${key}`);
const createDownloadResource = ({ id, type, dateRange }) =>
  api.post(`/customerEnvironment/${id}/downloads`, { type, dateRange });
const getDownloadResource = ({ id, type }) =>
  api.get(`/customerEnvironment/${id}/download`, {
    responseType: 'blob',
    params: {
      type,
    },
  });
const getSecurityAllPolicyDownloadUrl = ({ environmentId }) =>
  api.get(`/environmentApplication/${environmentId}/resource/all-policies`, {
    responseType: 'blob',
  });

const updateDownloadResourceStatus = ({ downloadId }) => api.patch(`downloads/${downloadId}`);

const getLastDownloads = ({ customEnvironmentId }) =>
  api.get(`customerEnvironment/${customEnvironmentId}/lastDownloadDates`);

const DownloadsService = {
  getDownloadsResources,
  getDownloadResourceStatus,
  updateDownloadsResources,
  getDownloadFile,
  getSecurityDownloadUrl,
  getDownloadResource,
  updateDownloadResourceStatus,
  createDownloadResource,
  getSecurityAllPolicyDownloadUrl,
  getLastDownloads,
};

export default DownloadsService;
