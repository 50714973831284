import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const Scrollbar = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'maxHeight' && prop !== 'height' && prop !== 'scrollbarThickness',
})(({ height = '100%', maxHeight = '100%', styles }) => ({
  overflow: 'auto',
  height,
  maxHeight,
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '4px',
    backgroundColor: '#9ca3af',
  },
  ...styles,
}));

export default Scrollbar;
