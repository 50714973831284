import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { colors } from '../../theme/palette/darkPalette';

const Container = styled(Box, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})(({ theme, backgroundColor }) => ({
  borderRadius: theme.spacing(1),
  maxWidth: 611,
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
  backgroundColor: backgroundColor,
  padding: '50px 52px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 700,
  lineHeight: '44px',
  color: theme.palette.text.primary,
}));

export const Description = styled(Typography)(({ theme }) => ({
  maxWidth: 375,
  width: '100%',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 300,
  lineHeight: '16px',
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const Wrapper = styled(Stack)({
  width: '100%',
  height: '100%',
});

const AlertPopup = ({ title, description, backgroundColor = colors.red, styles, children }) => (
  <Wrapper justifyContent="center" alignItems="center">
    <Container
      backgroundColor={backgroundColor}
      sx={{
        height: 334,
        ...styles,
      }}>
      <Box>
        <Title>{title}</Title>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3.5,
          mt: 2.25,
          height: '100%',
          justifyContent: 'center',
        }}>
        {description ? <Description>{description}</Description> : children}
      </Box>
    </Container>
  </Wrapper>
);

export default memo(AlertPopup);
