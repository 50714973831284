import api from './api';

const getApplications = ({ customerEnvironmentId, limit, page, query }) =>
  api.get(`/customerEnvironment/${customerEnvironmentId}/applications${query}`, {
    params: {
      ...(limit ? { Limit: limit } : {}),
      ...(page ? { Page: page } : {}),
    },
  });

const getApplicationVersions = ({ applicationId, page, limit }) =>
  api.get(`/environmentApplication/${applicationId}/versions`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getSecurityApplications = ({ customerEnvironmentId, query }) =>
  api.get(`/customerEnvironment/${customerEnvironmentId}/securities${query}`);

const getSecurityVersions = ({ applicationId, page, limit }) =>
  api.get(`/environmentApplication/${applicationId}/security-versions`, {
    params: {
      Page: page,
      Limit: limit,
    },
  });

const getSecurityDownloadUrl = ({ resourceId, key }) =>
  api.get(`/application/${resourceId}/resource/${key}`);

const getEnvironment = customerEnvironmentId =>
  api.get(`/customerEnvironment/${customerEnvironmentId}`);

const ApplicationsService = {
  getApplications,
  getApplicationVersions,
  getSecurityApplications,
  getSecurityVersions,
  getSecurityDownloadUrl,
  getEnvironment,
};

export default ApplicationsService;
