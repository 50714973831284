import { useSyncExternalStore } from 'react';

const subscribe = listener => {
  window.addEventListener('storage', listener);

  return () => window.removeEventListener('storage', listener);
};

const useSubscribeStorage = name =>
  useSyncExternalStore(subscribe, () => localStorage.getItem(name));

export default useSubscribeStorage;
