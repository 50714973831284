import * as Yup from 'yup';
import { pagesText } from '../constants/pagesText';

export const emailValidation = Yup.string()
  .trim()
  .email('Email must be a valid email address')
  .required('Email is required');

export const passwordValidation = Yup.string()
  .trim()
  .min(6, pagesText.passwordValidationFields.min)
  .matches(/[A-Z]/, pagesText.passwordValidationFields.uppercase)
  .matches(/[a-z]/, pagesText.passwordValidationFields.lowercase)
  .matches(/[0-9]/, pagesText.passwordValidationFields.numeric)
  .matches(/[^A-Za-z0-9]/, pagesText.passwordValidationFields.nonAlphanumeric)
  .required('Password is required.');
