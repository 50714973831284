import { memo } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const LegendRow = ({ title, color, onClick }) => (
  <Stack direction="row" gap={1} onClick={onClick}>
    <Box
      width={20}
      height={20}
      sx={{
        backgroundColor: color,
        cursor: 'pointer',
      }}
    />
    <Typography
      sx={{
        color: '#fff',
      }}>
      {title}
    </Typography>
  </Stack>
);

export default memo(LegendRow);
