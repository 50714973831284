import { useMutation } from 'react-query';
import { CUSTOMER_QUERY_KEY } from '../../constants/query';
import environmentService from '../../services/environment.service';
import useQueryData from '../useQueryData';
import { customerIdSelector } from '../../selectors/customer';

const useCreateEnvironment = () => {
  const customerId = useQueryData(CUSTOMER_QUERY_KEY, customerIdSelector);

  return useMutation({
    mutationFn: values =>
      environmentService.addEnvironment({
        name: values.name,
        configManagerSupportId: values.configManagerSupportId,
        description: values.description,
        location: values.location,
        customerId,
      })
  });
};

export default useCreateEnvironment;
