export const reportedDevicesMap = {
  Reported: 'Reported',
  NotReported: 'Not Reported',
};

export const DeviceStatus = {
  unknown: 'unknown',
  enforced: 'enforced',
  audit: 'audit',
};

export const healthStatus = {
  reported: 'reported',
  notReported: 'notReported',
};

export const reportedDevicesLabelToValue = {
  Reported: 'Reported',
  'Not Reported': 'NotReported',
};

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export const datePickerOptions = [
  {
    value: 5,
    label: 'Last 5 days',
  },
  {
    value: 10,
    label: 'Last 10 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
];
