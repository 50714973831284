import { useNavigate } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container } from "@mui/material";
// components
import Page from "../components/Page";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 768,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12),
}));

// ----------------------------------------------------------------------

export default function Error({ clearError }) {
  const navigate = useNavigate();
  return (
    <Page title="Unexpected Error">
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant="h3" paragraph>
            Sorry, an unexpected error has occurred!
          </Typography>
          <Typography paragraph>Something unexpected occurred.</Typography>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/");
              clearError();
            }}
          >
            Go to Home
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
