import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import DownloadsService from '../../services/downloads.service';
import { DOWNLOADS_QUERY_KEY } from '../../constants/query';
import { pagesText } from '../../constants/pagesText';

const useUpdateDownloadResources = ({ customerEnvId }) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ seen }) =>
      DownloadsService.updateDownloadsResources({ id: customerEnvId, seen }),
    onSuccess: data => {
      queryClient.setQueryData([DOWNLOADS_QUERY_KEY, customerEnvId], () => data);
      enqueueSnackbar(pagesText.notifications.markAllAsRead, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    },
    onError: () => {
      enqueueSnackbar(pagesText.somethingWentWrong, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    },
  });
};

export default useUpdateDownloadResources;
