export const isDateString = value => {
  if (value instanceof Date) return true;
  if (!value || typeof value !== 'string') return false;

  const versionPattern = /^\d+(\.\d+)+$/;
  if (versionPattern.test(value)) return false;

  return new Date(value).toString() !== 'Invalid Date';
};

export const getDateSelectedDayAgo = day => {
  const pastDay = new Date().getDate() - day + 1;
  const pastDateMiliseconds = new Date().setDate(pastDay);

  return new Date(pastDateMiliseconds);
};
