import { useCallback, useMemo, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PasswordFieldAdornment from '../../../components/PasswordFieldAdornment';
import { colors } from '../../../theme/palette/darkPalette';

const StyledTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'isReadyOnly',
})(({ isReadyOnly }) => ({
  width: '100%',
  '& label.Mui-focused': {
    color: colors.green,
  },
  '& .MuiInput-underline:after': {
    borderColor: '#D1D5DB',
  },
  '& .MuiInputBase-root': {
    ...(isReadyOnly
      ? {
          color: '#b5b5b5',
        }
      : {}),
    '& fieldset': {
      borderStyle: isReadyOnly ? 'dotted' : 'solid',
      borderColor: '#D1D5DB',
    },
  },
  '& .MuiInputBase-root .Mui-focused': {
    '& fieldset': {
      borderStyle: isReadyOnly ? 'dotted' : 'solid',
      borderColor: '#D1D5DB',
    },
  },
  '& .MuiFormHelperText-root': {
    color: colors.green,
    '&.Mui-error': {
      color: '#FF4842',
    },
  },
  '& .MuiInputLabel-root': {
    lineHeight: '20px',
  },
}));

const GeneralTextField = ({
  readOnly = false,
  label,
  type = 'text',
  defaultValue = null,
  error = false,
  helperText,
  showEditButton = true,
  FieldComponent,
  fieldComponentWidth = 70,
  endAdornment = null,
  variant = 'outlined',
  hasActionSlot = true,
  wrapperProps = {},
  AdditionalInfoField,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const textFieldRef = useRef(null);

  const onToggleShowPassword = useCallback(() => setShowPassword(prev => !prev), [setShowPassword]);

  const textFieldType = useMemo(() => {
    if (type !== 'password') return type;

    return showPassword ? 'text' : 'password';
  }, [type, showPassword]);

  return (
    <Stack direction="row" alignItems="baseline" gap={0.75} {...wrapperProps}>
      <Stack width="100%" gap={0.5}>
        <StyledTextField
          {...(!!defaultValue
            ? {
                defaultValue,
              }
            : {})}
          defaultValue={defaultValue}
          inputRef={textFieldRef}
          label={label}
          type={textFieldType}
          fullWidth
          variant={variant}
          error={error}
          InputProps={{
            autoComplete: 'new-password',
            sx: {
              '& input:-webkit-autofill': {
                WebkitBoxShadow: 'unset',
              },
            },
            readOnly,
            ...(type === 'password'
              ? {
                  endAdornment: (
                    <PasswordFieldAdornment
                      onClick={onToggleShowPassword}
                      showPassword={showPassword}
                    />
                  ),
                }
              : {}),
            ...(!!endAdornment ? { endAdornment } : {}),
          }}
          InputLabelProps={{
            sx: {
              fontSize: '16px',
              fontWeight: 400,
              color: '#fff',
              lineHeight: '14px',
            },
          }}
          isReadyOnly={readOnly}
          {...props}
        />
        {helperText !== undefined && (
          <Typography
            sx={{
              color: error ? '#FF4842' : colors.green,
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '12px',
              minHeight: '16px',
            }}>
            {helperText}
          </Typography>
        )}
        {!!AdditionalInfoField && <AdditionalInfoField />}
      </Stack>
      {!!FieldComponent ? (
        <Box width={fieldComponentWidth} height={56} sx={{ display: 'flex', alignItems: 'center' }}>
          <FieldComponent />
        </Box>
      ) : (
        (!readOnly || !showEditButton) && hasActionSlot && <Box width={70} height={40} />
      )}
    </Stack>
  );
};

export default GeneralTextField;
