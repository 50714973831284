import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { pagesText } from '../../../constants/pagesText';
import Iconify from '../../../components/Iconify';
import { colors } from '../../../theme/palette/darkPalette';
import Button from '../../../components/Button';
import Stack from '@mui/material/Stack';

const ConfirmTenantIdField = ({ selectedIdField, onClick }) => (
  <Stack gap={0.5} direction="row">
    <Typography sx={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
      {pagesText.environments.confirmTenantId[selectedIdField].title}
      <Tooltip
        placement="bottom"
        title={pagesText.environments.confirmTenantId[selectedIdField].infoMessage}>
        <Typography ml={1} component="span">
          <Iconify icon="ic:baseline-info" />
        </Typography>
      </Tooltip>
    </Typography>
    <Button
      variant="outlined"
      onClick={onClick}
      startIcon={<Iconify icon="material-symbols:check" />}
      sx={{
        color: colors.green,
        minWidth: '44px',
      }}>
      <Typography sx={{ fontSize: '12px' }}>
        {pagesText.environments.confirmTenantId[selectedIdField].buttonText}
      </Typography>
    </Button>
  </Stack>
);

export default ConfirmTenantIdField;
