import { useMutation } from 'react-query';
import environmentService from '../../services/environment.service';

const useRegenerateKey = () => {
  return useMutation({
    mutationFn: ({ activeEnvironmentId }) =>
      environmentService.changeEnvironmentDeviceLoggingKey({ customerEnvId: activeEnvironmentId }),
  });
};

export default useRegenerateKey;
