import { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchTableWrapper from '../../components/SearchTableWrapper';
import TableHeading from '../../components/Table/TableHeading';
import { columnsOrder, deviceStatuses, headings, reversedSortMap } from './constants';
import LoadingRows from '../../components/Table/LoadingRows';
import Row from '../../components/Table/Row';
import useDebounceHandler from '../../hooks/useDebounceHandler';
import useQueryParams from '../../hooks/useQueryParams';
import useQueryData from '../../hooks/useQueryData';
import { DEVICES_QUERY_KEY, USER_QUERY_KEY } from '../../constants/query';
import { activeCustomerEnvIdSelector } from '../../selectors/user';
import FiltersSidebar from './FilterSidebar';
import Iconify from '../../components/Iconify';
import useTableSort from '../../hooks/useTableSort';
import { sortingMethods } from '../../components/Table/Table';
import { defaultDeviceFilters } from '../../components/Filters/constants';
import NoDataCell from '../../components/NoDataCell';
import { pagesText } from '../../constants/pagesText';
import GenericTableCell, {
  formatTableCell,
  TableCellTypography,
} from '../../components/GenericTableCell';
import useMutateDeviceStatus from '../../hooks/devices/useMutateDeviceStatus';
import DeviceTagModal from './DeviceTagModal';
import useDialog from '../../hooks/useDialog';
import IconButton from '../../components/IconButton';
import { checkHasFilters } from '../../utils/table.util';
import useResetSearchParams from '../../hooks/useResetSearchParams';
import useRemoveDeviceTag from '../../hooks/devices/useRemoveDeviceTag';
import TagService from '../../services/tag.service';
import useRemoveListTag from '../../hooks/devices/useRemoveListTag';
import TableContainer from '../../components/Table/TableContainer';
import useFetchDevices from '../../hooks/useFetchDevices';
import SearchInputRow from '../../components/SearchInputRow';
import { formatDate } from '../../utils/formatTime';
import FilteredCountRow from '../../components/FilteredCountRow';

const StyledSelect = styled(Select)({
  '& svg': {
    fill: '#fff',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
});

const DevicesTable = () => {
  const customerEnvId = useQueryData(USER_QUERY_KEY, activeCustomerEnvIdSelector);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeTagName, setActiveTagName] = useState(null);
  const [activeTagId, setActiveTagId] = useState(null);
  useResetSearchParams();

  const deviceStatusMutation = useMutateDeviceStatus();
  const removeTagMutation = useRemoveListTag();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    open: isEditTagDialogOpen,
    onOpen: onEditTagDialogOpen,
    onClose: onEditTagDialogClose,
  } = useDialog();

  const {
    queryObject: { page, rowsPerPage, searchValue: initialSearchValue, filters = {} },
    changeFieldInURL,
    onResetSearchParams,
    onApplySearchParams,
  } = useQueryParams({ defaultRowsPerPage: 5 });
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [activeDeviceId, setActiveDeviceId] = useState(null);

  const {
    query,
    isLoading,
    data: devices,
    lastElementRef,
    isFetching,
    count,
  } = useFetchDevices({});

  const { onSort, onSortTable, onResetSortField, getListItemSortMethod, getCellSortMethod } =
    useTableSort({
      filters,
      onApplySearchParams,
      page,
      rowsPerPage,
      searchValue,
      defaultFilters: defaultDeviceFilters,
    });

  const onCloseFilters = useCallback(() => {
    setIsOpenFilters(false);
  }, [setIsOpenFilters]);

  const onOpenFilters = useCallback(() => {
    setIsOpenFilters(true);
  }, [setIsOpenFilters]);

  const debounceQueryHandler = useDebounceHandler((key, value) => {
    changeFieldInURL([
      { key, value },
      { key: 'page', value: 1 },
    ]);
  }, 500);

  const onChangeSearchValue = useCallback(
    event => {
      setSearchValue(event.target.value);
      debounceQueryHandler('search', event.target.value.toLowerCase());
    },
    [debounceQueryHandler, setSearchValue],
  );

  const onResetSearch = useCallback(() => {
    onResetSearchParams();
    setSearchValue('');
  }, [onResetSearchParams, setSearchValue]);

  const onApplyFilters = useCallback(
    filters => {
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage, searchValue });
    },
    [onApplySearchParams, rowsPerPage, searchValue],
  );

  const deviceTagMutation = useMutation(TagService.editDeviceTag);
  const deviceRemoveTagMutation = useRemoveDeviceTag();

  const onCellSort = useCallback(
    ({ field, sortMethod, sortingCellField, sortFieldName }) => {
      const reversedSortOrder =
        sortMethod === sortingMethods.initial ? sortingMethods.desc : reversedSortMap[sortMethod];
      onSort(field, reversedSortOrder, sortingCellField, sortFieldName);
    },
    [onSort],
  );

  const onChangeDeviceStatus = useCallback(
    (deviceId, status) => {
      deviceStatusMutation.mutate({
        deviceId,
        status,
        search: initialSearchValue,
        query,
      });
    },
    [deviceStatusMutation, initialSearchValue, query],
  );

  const onUpdateDeviceTag = useCallback(
    ({ tagName, tagId }) => {
      onEditTagDialogClose();
      if (!tagId && !tagName) return;

      deviceTagMutation.mutate(
        {
          tagName,
          deviceId: activeDeviceId,
          customerEnvId,
          tagId,
        },
        {
          onSuccess: () => {
            enqueueSnackbar(pagesText.devices.successFullyUpdateDeviceTag, {
              variant: 'success',
              autoHideDuration: 3000,
            });
            queryClient.invalidateQueries([DEVICES_QUERY_KEY, query]);
          },
          onError: () => {
            enqueueSnackbar(pagesText.somethingWentWrong, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          },
        },
      );

      if (tagName) {
        setActiveTagName(tagName);
      }
    },
    [
      enqueueSnackbar,
      queryClient,
      customerEnvId,
      deviceTagMutation,
      query,
      activeDeviceId,
      onEditTagDialogClose,
    ],
  );

  const onRemoveDeviceTag = useCallback(() => {
    onEditTagDialogClose();
    deviceRemoveTagMutation.mutate(
      {
        deviceId: activeDeviceId,
        tagId: activeTagId,
        search: initialSearchValue,
        query,
      },
      {
        onError: () => {
          enqueueSnackbar('Error removing tag', { variant: 'error' });
        },
      },
    );
  }, [
    deviceRemoveTagMutation,
    activeDeviceId,
    activeTagId,
    initialSearchValue,
    query,
    enqueueSnackbar,
    onEditTagDialogClose,
  ]);

  const onDeleteTag = useCallback(
    ({ tagId, tagSearch }) => {
      removeTagMutation.mutate({
        tagId,
        search: tagSearch,
        deviceSearch: initialSearchValue,
        deviceId: activeDeviceId,
        query,
      });
    },
    [removeTagMutation, activeDeviceId, initialSearchValue, query],
  );

  const onEditIconClick = useCallback(
    (deviceId, tagName, tagId) => {
      setActiveDeviceId(deviceId);
      setActiveTagName(tagName);
      setActiveTagId(tagId);
      onEditTagDialogOpen();
      onApplySearchParams({ filters, page: 1, limit: rowsPerPage, searchValue, tagId });
    },
    [
      onEditTagDialogOpen,
      setActiveDeviceId,
      onApplySearchParams,
      filters,
      rowsPerPage,
      searchValue,
    ],
  );

  const hasFilters = useMemo(() => checkHasFilters(filters), [filters]);

  const renderCustomCell = useCallback(
    (column, row) => {
      if (column === 'tagName') {
        return (
          <TableCell>
            <Stack direction="row" alignItems="center" gap={2}>
              <TableCellTypography>{formatTableCell(row.tagName)}</TableCellTypography>
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  onEditIconClick(row.id, row.tagName, row.tagId);
                }}>
                <Iconify icon="ic:baseline-edit" color="#a2a2a2" width={24} height={24} />
              </IconButton>
            </Stack>
          </TableCell>
        );
      }

      if (column === 'status') {
        return (
          <TableCell
            onClick={e => {
              e.stopPropagation();
            }}>
            <StyledSelect
              value={row.status.toLowerCase()}
              label=""
              onChange={event => onChangeDeviceStatus(row.id, event.target.value)}>
              {deviceStatuses.map(status => (
                <MenuItem value={status.value}>{status.label}</MenuItem>
              ))}
            </StyledSelect>
          </TableCell>
        );
      }

      if (column === 'lastContactDate') {
        return (
          <GenericTableCell
            key={row[column?.name || column]}
            value={formatDate(row[column?.name || column])}
            styles={column?.styles || {}}
            tooltipText={column?.tooltipText}
          />
        );
      }

      return (
        <GenericTableCell
          key={row[column?.name || column]}
          value={row[column?.name || column]}
          styles={column?.styles || {}}
          tooltipText={column?.tooltipText}
        />
      );
    },
    [onChangeDeviceStatus, onEditIconClick],
  );

  const closeEditTagDialog = useCallback(() => {
    onEditTagDialogClose();
    setActiveDeviceId(null);
    setActiveTagName(null);
    setActiveTagId(null);
  }, [onEditTagDialogClose]);

  const filteredCount = useMemo(() => {
    return !isLoading && (hasFilters || searchValue) ? count : null;
  }, [count, hasFilters, isLoading, searchValue]);

  return (
    <SearchTableWrapper>
      <TableContainer>
        <Table stickyHeader aria-label="collapsible table">
          <TableHeading
            headings={headings}
            hasFilter
            hasFilters={hasFilters}
            onOpenFilters={onOpenFilters}
            onSortTable={onSortTable}
            onResetSortField={onResetSortField}
            getListItemSortMethod={getListItemSortMethod}
            getCellSortMethod={getCellSortMethod}
            onCellSort={onCellSort}
            listCellStyles={{
              top: '80px',
            }}>
            <FilteredCountRow
              count={filteredCount}
              styles={{
                backgroundColor: '#333333 !important',
              }}
              colSpan={columnsOrder.length + 1}
            />
            <SearchInputRow
              searchValue={searchValue}
              onChangeSearchValue={onChangeSearchValue}
              onResetSearch={onResetSearch}
              placeholder="Enter a keyword"
              cellStyles={{
                top: '24px',
              }}
              colSpan={columnsOrder.length + 1}
            />
          </TableHeading>
          <TableBody sx={{ backgroundColor: '#4B4B4B' }}>
            {isLoading ? (
              <LoadingRows columnCount={columnsOrder.length} rowCount={5} />
            ) : (
              <>
                {devices?.map((row, index) => (
                  <Row
                    ref={devices.length - 1 === index ? lastElementRef : null}
                    key={row.id}
                    row={row}
                    hasActions
                    columnData={columnsOrder}
                    renderCustomCell={renderCustomCell}
                    ActionComponent={() => <TableCell></TableCell>}
                    source="devices"
                    isClickable={true}
                  />
                ))}
                {!isLoading && isFetching && (
                  <LoadingRows columnCount={columnsOrder.length + 1} rowCount={5} />
                )}
                {!isLoading && !devices?.length && (
                  <NoDataCell colSpan={columnsOrder.length + 1}>{pagesText.noDataFound}</NoDataCell>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <FiltersSidebar
        open={isOpenFilters}
        onClose={onCloseFilters}
        onApplyFilters={onApplyFilters}
        initialQueryFilters={filters}
      />
      <DeviceTagModal
        isOpen={isEditTagDialogOpen}
        onClose={closeEditTagDialog}
        onUpdateDeviceTag={onUpdateDeviceTag}
        tagName={activeTagName}
        activeTagId={activeTagId}
        onRemoveDeviceTag={onRemoveDeviceTag}
        onDeleteTag={onDeleteTag}
      />
    </SearchTableWrapper>
  );
};

export default DevicesTable;
