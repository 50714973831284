import { memo } from 'react';
import ListItemText, { listItemTextClasses } from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import Stack from '@mui/material/Stack';
import TableAppImage from './TableAppImage';
import Skeleton from '../Skeleton';

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: prop => prop !== 'isRowActive',
})(({ theme, isRowActive }) => ({
  [`& .${listItemTextClasses.primary}`]: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    color: isRowActive ? '#000' : '#fff',
  },
  [`& .${listItemTextClasses.secondary}`]: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '22px',
    color: '#DDDDDD',
  },
  maxWidth: 400,
}));

const TableNameCell = ({ name, imgSrc, version, loading, isRowActive, ...props }) => (
  <TableCell
    {...props}
    scope="row"
    sx={{
      paddingRight: 0,
      color: 'inherit',
      ...props.sx,
    }}>
    <Stack direction="row" gap={2} alignItems="center">
      <TableAppImage name={name} imgSrc={imgSrc} loading={loading} />
      {loading ? (
        <Skeleton variant="text" animation="pulse" width="100%" height={22} />
      ) : (
        <StyledListItemText primary={name} secondary={version} isRowActive={isRowActive} />
      )}
    </Stack>
  </TableCell>
);

export default memo(TableNameCell);
