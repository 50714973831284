import { SnackbarProvider } from 'notistack';
import Box from '@mui/material/Box';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import AutoLogon from './components/AutoLogon';
import ErrorBoundary from './components/ErrorBoundary';
import './index.css';
import Scrollbar from './components/Scrollbar';

export default function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <ScrollToTop />
        <AutoLogon>
          <Box
            bgcolor="background.default"
            sx={{
              height: '100%',
            }}>
            <Scrollbar scrollbarThickness="8px">
              <ErrorBoundary>
                <Router />
              </ErrorBoundary>
            </Scrollbar>
          </Box>
        </AutoLogon>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
