import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Wrapper = styled(Box)({
  maxWidth: '846px',
  width: '100%',
  borderRadius: '10px',
  boxShadow: '0px 6px 20px 4px rgba(33, 33, 33, 0.08)',
  padding: '59px 16px 73px 16px',
});

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(24),
  fontWeight: 800,
  lineHeight: '28px',
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 300,
  lineHeight: '16px',
}));

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

/**
 * Auth Component.
 *
 * @param {string} title - The title for the authentication form.
 * @param {string} subtitle - The subtitle for the authentication form.
 * @param {JSX.Element} Subtitle - JSX element representing the subtitle for the authentication form.
 * @param {JSX.Element} Form - JSX element representing the main authentication form.
 * @param {JSX.Element} Actions - JSX element representing the authentication actions.
 * @returns {JSX.Element} - JSX element representing the Auth component.
 */

const Auth = ({ title, subtitle, Subtitle, Form, Actions }) => (
  <Container>
    <Wrapper bgcolor="background.paper">
      <Box display="flex" flexDirection="column" alignItems="center" gap={1.25}>
        {!!title && <StyledTitle>{title}</StyledTitle>}
        {!!subtitle && (
          <Box display="flex" alignItems="center" height={30}>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
          </Box>
        )}
        {!!Subtitle && <Subtitle />}
      </Box>
      {!!Form && <Form />}
      {!!Actions && <Actions />}
    </Wrapper>
  </Container>
);

export default Auth;
